import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';

export async function updateUserPhone(userId: string, phone: string): Promise<void> {
  try {
    const userRef = doc(db, 'users', userId);
    const profileRef = doc(userRef, 'profile', 'contact');
    
    await setDoc(profileRef, {
      phoneNumber: phone,
      updatedAt: new Date().toISOString()
    }, { merge: true });
  } catch (error) {
    console.error('Error updating phone number:', error);
    throw new Error('Failed to update phone number');
  }
}

export async function getUserProfile(userId: string) {
  try {
    const profileRef = doc(db, 'users', userId, 'profile', 'contact');
    const profileDoc = await getDoc(profileRef);
    return profileDoc.exists() ? profileDoc.data() : null;
  } catch (error) {
    console.error('Error getting user profile:', error);
    throw new Error('Failed to get user profile');
  }
}