import { useState } from 'react';
import { motion } from 'framer-motion';
import { Brain, MessageSquare } from 'lucide-react';
import { useLanguage } from '../contexts/LanguageContext';
import DirectChat from '../components/chat/DirectChat';
import CourseGenerator from '../components/courses/CourseGenerator';

export default function AIAssistant() {
  const { t, language } = useLanguage();
  const [mode, setMode] = useState<'chat' | 'course' | null>(null);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="container mx-auto px-4 py-8"
    >
      <div className="max-w-6xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-8"
          dir={language === 'ar' ? 'rtl' : 'ltr'}
        >
          <div className="inline-flex items-center justify-center p-3 bg-emerald-100 rounded-full mb-4">
            <Brain className="h-8 w-8 text-emerald-600" />
          </div>
          <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
            {t('ai.title')}
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto mb-8">
            {t('ai.description')}
          </p>

          {!mode && (
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setMode('chat')}
                className="w-full sm:w-auto flex items-center justify-center gap-2 bg-white text-emerald-600 px-8 py-3 rounded-lg font-medium hover:bg-emerald-50 transition transform hover:-translate-y-0.5 shadow-lg"
              >
                <MessageSquare className="h-5 w-5" />
                {t('ai.startChat')}
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setMode('course')}
                className="w-full sm:w-auto flex items-center justify-center gap-2 bg-emerald-500 text-white px-8 py-3 rounded-lg font-medium hover:bg-emerald-400 transition transform hover:-translate-y-0.5 shadow-lg"
              >
                <Brain className="h-5 w-5" />
                {t('course.generate')}
              </motion.button>
            </div>
          )}
        </motion.div>

        {mode && (
          <div className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden">
            {mode === 'chat' ? <DirectChat /> : <CourseGenerator />}
          </div>
        )}
      </div>
    </motion.div>
  );
}