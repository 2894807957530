export class OpenAIError extends Error {
  constructor(message: string, public readonly cause?: unknown) {
    super(message);
    this.name = 'OpenAIError';
  }
}

export class AssistantError extends OpenAIError {
  constructor(message: string, cause?: unknown) {
    super(message, cause);
    this.name = 'AssistantError';
  }
}

export class ThreadError extends OpenAIError {
  constructor(message: string, cause?: unknown) {
    super(message, cause);
    this.name = 'ThreadError';
  }
}

export class RunError extends OpenAIError {
  constructor(message: string, cause?: unknown) {
    super(message, cause);
    this.name = 'RunError';
  }
}