import { User } from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth } from '../../contexts/AuthContext';

export default function ProfileHeader() {
  const { t } = useLanguage();
  const { currentUser } = useAuth();
  
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div className="p-3 bg-emerald-100 rounded-full">
          <User className="h-6 w-6 text-emerald-600" />
        </div>
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            {currentUser?.displayName || t('profile.guest')}
          </h1>
          <p className="text-gray-600">{currentUser?.email}</p>
        </div>
      </div>
      <div className="border-b border-gray-200 pb-4">
        <p className="text-gray-600">{t('profile.subtitle')}</p>
      </div>
    </div>
  );
}