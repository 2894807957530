import { app } from './en/app';
import { auth } from './en/auth';
import { ai } from './en/ai';
import { course } from './en/course';
import { benefits } from './en/benefits';
import { languages } from './en/languages';
import { subscription } from './en/subscription';
import { faq } from './en/faq';

export const en = {
  app,
  auth,
  ai,
  course,
  benefits,
  languages,
  subscription,
  faq
};