import { PAYPAL_SDK_CONFIG } from './config';

export function loadPayPalScript(): Promise<void> {
  return new Promise((resolve, reject) => {
    // Check if script already exists
    if (document.getElementById(PAYPAL_SDK_CONFIG.scriptId)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.id = PAYPAL_SDK_CONFIG.scriptId;
    
    const params = new URLSearchParams({
      'client-id': PAYPAL_SDK_CONFIG.clientId,
      ...PAYPAL_SDK_CONFIG.options
    });

    script.src = `${PAYPAL_SDK_CONFIG.scriptUrl}?${params.toString()}`;
    script.async = true;
    script.setAttribute('data-sdk-integration-source', 'button-factory');

    script.onload = () => resolve();
    script.onerror = () => reject(new Error('Failed to load PayPal SDK'));

    document.body.appendChild(script);
  });
}

export function cleanupPayPalScript(): void {
  const script = document.getElementById(PAYPAL_SDK_CONFIG.scriptId);
  if (script) {
    script.remove();
  }

  // Cleanup any existing buttons
  const container = document.getElementById(PAYPAL_SDK_CONFIG.buttonContainerId);
  if (container) {
    container.innerHTML = '';
  }
}