export const course = {
  title: 'Learning Courses',
  description: 'Courses tailored to your level',
  generate: 'Create New Course',
  savedCourses: 'Saved Courses',
  noSavedCourses: 'No saved courses yet',
  continue: 'Continue Course',
  back: 'Back',
  generating: 'Generating Your Course...',
  generatingDescription: 'Please wait while we create a personalized learning experience for you',
  level: {
    beginner: 'Beginner',
    intermediate: 'Intermediate', 
    advanced: 'Advanced',
    beginnerDesc: 'Perfect for those just starting their language journey',
    intermediateDesc: 'For learners with basic knowledge seeking to improve',
    advancedDesc: 'For experienced learners aiming for fluency'
  }
};