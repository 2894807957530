import { useState, useCallback } from 'react';
import { Message, ConversationContext } from '../types/chat';
import { analyzeMessage } from '../lib/services/chat/messageAnalyzer';
import { buildPrompt } from '../lib/services/chat/promptBuilder';
import { generateChatResponse } from '../lib/services/openai';
import { LearningLanguage } from '../types/language';

export function useChat() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [context, setContext] = useState<ConversationContext>({});
  const [isLoading, setIsLoading] = useState(false);

  const updateContext = useCallback((message: string, isAiResponse: boolean) => {
    setContext(prevContext => {
      const analysis = analyzeMessage(message);
      
      return {
        ...prevContext,
        lastQuestion: isAiResponse && analysis.isQuestion ? message : prevContext.lastQuestion,
        lastTopic: analysis.topic || prevContext.lastTopic,
        expectingAnswer: isAiResponse && analysis.isQuestion,
        pendingResponse: !isAiResponse && analysis.expectsResponse
      };
    });
  }, []);

  const addMessage = useCallback((content: string, sender: 'user' | 'ai') => {
    const message: Message = {
      id: Date.now().toString(),
      content,
      sender,
      timestamp: new Date()
    };
    setMessages(prev => [...prev, message]);
    updateContext(content, sender === 'ai');
    return message;
  }, [updateContext]);

  const sendMessage = useCallback(async (
    content: string, 
    language?: LearningLanguage,
    lessonContext?: { id: string; title: string; content: string; }
  ) => {
    if (!content.trim() || isLoading) return;

    if (lessonContext) {
      setContext(prev => ({
        ...prev,
        currentLesson: lessonContext
      }));
    }

    addMessage(content, 'user');
    setIsLoading(true);

    try {
      const prompt = buildPrompt(messages, context, content);
      const response = await generateChatResponse(prompt, language || 'en');
      addMessage(response, 'ai');
    } catch (error) {
      console.error('Chat error:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [messages, context, isLoading, addMessage]);

  return {
    messages,
    isLoading,
    sendMessage,
    addMessage,
    context
  };
}