import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { Assistant, assistants } from '../types/assistant';
import { useUserPreferences } from './UserPreferencesContext';

interface AssistantContextType {
  currentAssistant: Assistant | null;
  setCurrentAssistant: (assistant: Assistant | null) => void;
  availableAssistants: Assistant[];
  recommendedAssistant: Assistant | null;
}

const AssistantContext = createContext<AssistantContextType | undefined>(undefined);

export function AssistantProvider({ children }: { children: ReactNode }) {
  const { preferences } = useUserPreferences();
  const [currentAssistant, setCurrentAssistant] = useState<Assistant | null>(null);
  const [recommendedAssistant, setRecommendedAssistant] = useState<Assistant | null>(null);

  useEffect(() => {
    if (!preferences) return;

    // Find the best assistant based on user preferences
    const findBestAssistant = () => {
      const bestMatch = assistants.find(assistant => {
        const matchesLanguage = assistant.language === preferences.targetLanguage;
        const isAppropriateLevel = preferences.proficiencyLevel === 'beginner' 
          ? assistant.specialties.includes('pronunciation')
          : preferences.proficiencyLevel === 'advanced'
          ? assistant.specialties.includes('academic')
          : true;
        return matchesLanguage && isAppropriateLevel;
      });

      return bestMatch || assistants[0];
    };

    const bestAssistant = findBestAssistant();
    setRecommendedAssistant(bestAssistant);
    
    // Only set current assistant if none is selected
    if (!currentAssistant) {
      setCurrentAssistant(bestAssistant);
    }
  }, [preferences, currentAssistant]);

  return (
    <AssistantContext.Provider value={{
      currentAssistant,
      setCurrentAssistant,
      availableAssistants: assistants,
      recommendedAssistant
    }}>
      {children}
    </AssistantContext.Provider>
  );
}

export function useAssistant() {
  const context = useContext(AssistantContext);
  if (context === undefined) {
    throw new Error('useAssistant must be used within an AssistantProvider');
  }
  return context;
}