import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Clock, MessageSquare, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth } from '../../contexts/AuthContext';
import { getSavedCourses, deleteSavedCourse } from '../../lib/services/firebase/courses';

interface SavedCoursesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SavedCoursesModal({ isOpen, onClose }: SavedCoursesModalProps) {
  const { t, language } = useLanguage();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [courses, setCourses] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isOpen || !currentUser) return;

    const loadCourses = async () => {
      try {
        const savedCourses = await getSavedCourses(currentUser.uid);
        setCourses(savedCourses);
      } catch (error) {
        console.error('Error loading saved courses:', error);
      } finally {
        setLoading(false);
      }
    };

    loadCourses();
  }, [isOpen, currentUser]);

  const handleContinueCourse = (course: any) => {
    navigate('/chat', { 
      state: { 
        course: course.course,
        messages: course.messages
      }
    });
    onClose();
  };

  const handleDelete = async (courseId: string) => {
    try {
      await deleteSavedCourse(courseId);
      setCourses(prev => prev.filter(c => c.id !== courseId));
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-white rounded-xl shadow-xl max-w-2xl w-full max-h-[90vh] overflow-hidden"
        >
          <div className="p-6 border-b sticky top-0 bg-white z-10">
            <div className="flex items-center justify-between">
              <h2 className="text-2xl font-bold text-gray-900">
                {t('course.savedCourses')}
              </h2>
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>

          <div className="p-6 overflow-y-auto">
            {loading ? (
              <div className="flex justify-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-emerald-500 border-t-transparent" />
              </div>
            ) : courses.length === 0 ? (
              <div className="text-center py-8 text-gray-500">
                {t('course.noSavedCourses')}
              </div>
            ) : (
              <div className="space-y-4">
                {courses.map((course) => (
                  <div
                    key={course.id}
                    className="bg-white rounded-lg border border-gray-200 p-4 hover:border-emerald-200 transition-colors"
                  >
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="font-medium text-gray-900">
                        {course.course.title}
                      </h3>
                      <button
                        onClick={() => handleDelete(course.id)}
                        className="p-1 text-red-500 hover:bg-red-50 rounded-lg transition"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                    
                    <div className="flex items-center gap-4 text-sm text-gray-500 mb-4">
                      <div className="flex items-center gap-1">
                        <Clock className="h-4 w-4" />
                        <span>
                          {new Date(course.lastAccessed).toLocaleDateString(
                            language === 'ar' ? 'ar-SA' : 'en-US'
                          )}
                        </span>
                      </div>
                      <div className="flex items-center gap-1">
                        <MessageSquare className="h-4 w-4" />
                        <span>{course.messages?.length || 0} messages</span>
                      </div>
                    </div>

                    <button
                      onClick={() => handleContinueCourse(course)}
                      className="w-full bg-emerald-600 text-white py-2 rounded-lg hover:bg-emerald-700 transition"
                    >
                      {t('course.continue')}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}