import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBY6XbP-DLQdqVNwvJbvh1hk53XgAddzGU",
  authDomain: "learn-4a13a.firebaseapp.com",
  projectId: "learn-4a13a",
  storageBucket: "learn-4a13a.firebasestorage.app",
  messagingSenderId: "235872385928",
  appId: "1:235872385928:web:218a19621064a1f805983d",
  measurementId: "G-S9W02WM3KC"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();