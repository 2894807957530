import { useState } from 'react';
import { motion } from 'framer-motion';
import { Send } from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';

interface ChatInputProps {
  onSendMessage: (content: string) => Promise<void>;
  isLoading: boolean;
  placeholder?: string;
}

export default function ChatInput({ 
  onSendMessage, 
  isLoading,
  placeholder 
}: ChatInputProps) {
  const { language } = useLanguage();
  const [input, setInput] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;

    await onSendMessage(input);
    setInput('');
  };

  return (
    <div className="sticky bottom-0 p-4 border-t bg-white/95 backdrop-blur-sm shadow-lg">
      <form onSubmit={handleSubmit} className="flex gap-2 max-w-3xl mx-auto">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={placeholder}
          className="flex-1 px-4 py-3 border rounded-lg focus:ring-2 focus:ring-emerald-500 focus:outline-none text-base"
          disabled={isLoading}
          dir={language === 'ar' ? 'rtl' : 'ltr'}
        />
        
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="submit"
          disabled={isLoading || !input.trim()}
          className="bg-emerald-600 text-white p-3 rounded-lg hover:bg-emerald-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300"
        >
          <Send className="h-5 w-5" />
        </motion.button>
      </form>
    </div>
  );
}