export const PAYPAL_SDK_CONFIG = {
  scriptId: 'paypal-sdk',
  scriptUrl: 'https://www.paypal.com/sdk/js',
  clientId: 'AZfKvNq2UbEbxX1OgeQF-jmtQ5OmXn8FPvhjBGrXqtDy0JCVokUraqcJ84mXO_beRSnTlnldrYeZ673J',
  planId: 'P-2DA04584TR815370AM5OHWLY',
  buttonContainerId: 'paypal-button-container',
  options: {
    vault: true,
    intent: 'subscription'
  }
};