import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Star } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';

interface SubjectCardProps {
  code: string;
  title: string;
  description: string;
  image: string;
  review: {
    name: string;
    arabicName: string;
    rating: number;
    reviews: number;
    flag: string;
  };
  delay?: number;
}

export default function SubjectCard({ 
  code,
  title, 
  description, 
  image,
  review,
  delay = 0 
}: SubjectCardProps) {
  const { language } = useLanguage();

  return (
    <Link to="/ai-assistant">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay }}
        className="group relative overflow-hidden rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black/60 z-10" />
        <img 
          src={image} 
          alt={title}
          className="w-full h-48 sm:h-56 object-cover object-center transform group-hover:scale-110 transition-transform duration-300"
        />
        <div className="absolute bottom-0 left-0 right-0 p-4 sm:p-6 text-white z-20">
          <div className="flex items-center gap-3 mb-2">
            <div className="p-2 bg-white/20 backdrop-blur-sm rounded-lg">
              <span className="text-2xl">{review.flag}</span>
            </div>
            <div>
              <h3 className="text-lg sm:text-xl font-bold">{title}</h3>
              <div className="flex items-center gap-2 text-sm text-white/90">
                <span>{language === 'ar' ? review.arabicName : review.name}</span>
                <span>•</span>
                <div className="flex items-center">
                  <Star className="h-4 w-4 fill-yellow-400 text-yellow-400" />
                  <span className="ml-1">{review.rating}</span>
                </div>
                <span>•</span>
                <span>{review.reviews.toLocaleString()} {language === 'ar' ? 'تقييم' : 'reviews'}</span>
              </div>
            </div>
          </div>
          
          <p className="text-white/90 text-sm mb-4 line-clamp-2">{description}</p>
          
          <motion.div
            whileHover={{ x: 5 }}
            className="flex items-center gap-2 text-sm font-medium text-emerald-300 hover:text-emerald-200 transition-colors"
          >
            {language === 'ar' ? 'ابدأ التعلم' : 'Start Learning'}
            <ArrowRight className="h-4 w-4" />
          </motion.div>
        </div>
      </motion.div>
    </Link>
  );
}