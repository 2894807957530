import { openai } from './config';
import { RunError } from './errors';
import { withRetry } from './retry';
import { validateResponse } from './validation';

export async function createRun(
  threadId: string,
  assistantId: string,
  instructions: string
) {
  return withRetry(async () => {
    try {
      return await openai.beta.threads.runs.create(threadId, {
        assistant_id: assistantId,
        instructions
      });
    } catch (error) {
      throw new RunError('Failed to create run', error);
    }
  });
}

export async function waitForRun(threadId: string, runId: string): Promise<string> {
  let attempts = 0;
  const maxAttempts = 30; // 30 seconds timeout
  
  while (attempts < maxAttempts) {
    try {
      const runStatus = await openai.beta.threads.runs.retrieve(threadId, runId);

      if (runStatus.status === 'completed') {
        const messages = await openai.beta.threads.messages.list(threadId);
        const response = messages.data[0]?.content[0]?.text?.value;
        
        return validateResponse(
          response,
          'No response content found in completed run'
        );
      }

      if (runStatus.status === 'failed') {
        throw new RunError(`Run failed: ${runStatus.last_error?.message || 'Unknown error'}`);
      }

      if (runStatus.status === 'cancelled') {
        throw new RunError('Run was cancelled');
      }

      if (runStatus.status === 'expired') {
        throw new RunError('Run expired');
      }

      // Wait before checking again
      await new Promise(resolve => setTimeout(resolve, 1000));
      attempts++;
    } catch (error) {
      if (error instanceof RunError) {
        throw error;
      }
      throw new RunError('Failed to check run status', error);
    }
  }

  throw new RunError('Run timed out');
}