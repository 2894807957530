import { updateSubscriptionAfterPayment } from '../firebase/subscription';

export async function handlePayPalSubscription(
  userId: string,
  subscriptionData: { subscriptionID: string }
): Promise<void> {
  try {
    // Update Firebase with the new subscription
    await updateSubscriptionAfterPayment(userId, subscriptionData.subscriptionID);
  } catch (error) {
    console.error('Error handling PayPal subscription:', error);
    throw new Error('Failed to process subscription');
  }
}