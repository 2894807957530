import { motion } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';
import { LearningLanguage, learningLanguages } from '../../types/language';

interface LanguageSelectorProps {
  onSelect: (language: LearningLanguage) => void;
}

export default function LanguageSelector({ onSelect }: LanguageSelectorProps) {
  const { t } = useLanguage();

  return (
    <div className="p-4 sm:p-8 space-y-8 h-full overflow-y-auto">
      <div className="text-center">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          {t('ai.selectLanguage')}
        </h2>
        <p className="text-gray-600">
          {t('ai.languageDescription')}
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-4xl mx-auto">
        {learningLanguages.map((lang) => (
          <motion.button
            key={lang.code}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => onSelect(lang.code)}
            className="p-6 bg-white rounded-xl shadow-sm border border-gray-200 hover:border-emerald-200 transition-all text-left"
          >
            <div className="flex items-center gap-3">
              <span className="text-3xl">{lang.flag}</span>
              <div>
                <h3 className="font-medium text-gray-900">{lang.name}</h3>
                <p className="text-sm text-gray-500">{lang.nativeName}</p>
              </div>
            </div>
          </motion.button>
        ))}
      </div>
    </div>
  );
}