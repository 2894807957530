import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { UserSubscription } from '../../../types/subscription';

export async function getUserSubscription(userId: string): Promise<UserSubscription | null> {
  try {
    const subscriptionRef = doc(db, 'subscriptions', userId);
    const subscriptionDoc = await getDoc(subscriptionRef);
    
    if (!subscriptionDoc.exists()) {
      // Initialize free subscription if none exists
      const freeSubscription: UserSubscription = {
        userId,
        planId: 'free',
        status: 'active',
        startDate: new Date(),
        endDate: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000), // 1 year
        messageCount: 0,
        lastResetDate: new Date()
      };
      
      await setDoc(subscriptionRef, {
        ...freeSubscription,
        startDate: freeSubscription.startDate.toISOString(),
        endDate: freeSubscription.endDate.toISOString(),
        lastResetDate: freeSubscription.lastResetDate.toISOString()
      });
      
      return freeSubscription;
    }

    const data = subscriptionDoc.data();
    return {
      ...data,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      lastResetDate: new Date(data.lastResetDate)
    } as UserSubscription;
  } catch (error) {
    console.error('Error getting subscription:', error);
    return null;
  }
}

export async function updateSubscriptionStatus(
  userId: string,
  planId: 'free' | 'premium',
  status: 'active' | 'cancelled' | 'expired'
): Promise<void> {
  const subscriptionRef = doc(db, 'subscriptions', userId);
  
  await setDoc(subscriptionRef, {
    planId,
    status,
    messageCount: 0,
    lastResetDate: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  }, { merge: true });
}

export async function incrementMessageCount(userId: string): Promise<boolean> {
  const subscription = await getUserSubscription(userId);
  if (!subscription) return false;

  // Premium users have unlimited messages
  if (subscription.planId === 'premium' && subscription.status === 'active') {
    return true;
  }

  // Check daily message limit for free users
  if (subscription.messageCount >= 10) {
    return false;
  }

  const subscriptionRef = doc(db, 'subscriptions', userId);
  await setDoc(subscriptionRef, {
    messageCount: (subscription.messageCount || 0) + 1,
    lastResetDate: new Date().toISOString()
  }, { merge: true });

  return true;
}