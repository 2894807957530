import { motion } from 'framer-motion';
import { BookOpen } from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';

interface SavedCoursesButtonProps {
  onClick: () => void;
}

export default function SavedCoursesButton({ onClick }: SavedCoursesButtonProps) {
  const { t } = useLanguage();

  return (
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={onClick}
      className="flex items-center gap-2 bg-emerald-100 text-emerald-700 px-4 py-2 rounded-lg hover:bg-emerald-200 transition"
    >
      <BookOpen className="h-5 w-5" />
      <span>{t('course.savedCourses')}</span>
    </motion.button>
  );
}