import { collection, addDoc, query, where, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Course } from '../../../types/course';

interface SavedCourse {
  id: string;
  userId: string;
  course: Course;
  lastAccessed: string;
  messages: {
    content: string;
    sender: 'user' | 'ai';
    timestamp: string;
  }[];
}

export async function saveCourse(
  userId: string,
  course: Course,
  messages: any[] = []
): Promise<string> {
  try {
    const courseData = {
      userId,
      course,
      lastAccessed: new Date().toISOString(),
      messages: messages.map(msg => ({
        content: msg.content,
        sender: msg.sender,
        timestamp: msg.timestamp.toISOString()
      }))
    };

    const docRef = await addDoc(collection(db, 'savedCourses'), courseData);
    return docRef.id;
  } catch (error) {
    console.error('Error saving course:', error);
    throw new Error('Failed to save course');
  }
}

export async function getSavedCourses(userId: string): Promise<SavedCourse[]> {
  try {
    const q = query(
      collection(db, 'savedCourses'),
      where('userId', '==', userId)
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as SavedCourse[];
  } catch (error) {
    console.error('Error getting saved courses:', error);
    throw new Error('Failed to load saved courses');
  }
}

export async function deleteSavedCourse(courseId: string): Promise<void> {
  try {
    await deleteDoc(doc(db, 'savedCourses', courseId));
  } catch (error) {
    console.error('Error deleting course:', error);
    throw new Error('Failed to delete course');
  }
}