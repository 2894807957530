export type Language = 'en' | 'ar';
export type LearningLanguage = 'en' | 'fr' | 'es' | 'zh' | 'ja';

export interface LanguageOption {
  code: Language;
  name: string;
  nativeName: string;
  flag: string;
}

export interface LearningLanguageOption {
  code: LearningLanguage;
  name: string;
  nativeName: string;
  flag: string;
  assistantId: string;
}

export const languages: LanguageOption[] = [
  {
    code: 'ar',
    name: 'Arabic',
    nativeName: 'العربية',
    flag: '🇸🇦'
  },
  {
    code: 'en',
    name: 'English',
    nativeName: 'English',
    flag: '🇬🇧'
  }
];

export const learningLanguages: LearningLanguageOption[] = [
  {
    code: 'en',
    name: 'English',
    nativeName: 'English',
    flag: '🇬🇧',
    assistantId: 'asst_23GLsCD6VbmjUv0w0JN3FDO5'
  },
  {
    code: 'fr',
    name: 'French',
    nativeName: 'Français',
    flag: '🇫🇷',
    assistantId: 'asst_ViuuifbWGiUC74v2OE0ikImL'
  },
  {
    code: 'es',
    name: 'Spanish',
    nativeName: 'Español',
    flag: '🇪🇸',
    assistantId: 'asst_siNTOgN3WdwIwgKJT0WIgXTf'
  },
  {
    code: 'zh',
    name: 'Chinese',
    nativeName: '中文',
    flag: '🇨🇳',
    assistantId: 'asst_YuJNSFH6p8yYUQ7K6VipA82B'
  },
  {
    code: 'ja',
    name: 'Japanese',
    nativeName: '日本語',
    flag: '🇯🇵',
    assistantId: 'asst_h4ZAJY2RvBqq0CfyxhIFK7LY'
  }
];

export function getLearningLanguageOption(code: LearningLanguage): LearningLanguageOption {
  return learningLanguages.find(lang => lang.code === code) || learningLanguages[0];
}

export function getAssistantIdForLanguage(code: LearningLanguage): string {
  const language = getLearningLanguageOption(code);
  return language.assistantId;
}