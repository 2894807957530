import { openai } from '../config';
import { ThreadError } from '../errors';

export async function createThread() {
  try {
    return await openai.beta.threads.create();
  } catch (error) {
    throw new ThreadError('Failed to create thread', error);
  }
}

export async function addMessageToThread(
  threadId: string,
  content: string,
  role: 'user' | 'assistant' | 'system' = 'user'
) {
  try {
    return await openai.beta.threads.messages.create(threadId, {
      role,
      content
    });
  } catch (error) {
    throw new ThreadError('Failed to add message to thread', error);
  }
}