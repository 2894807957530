import { Message } from '../../types/chat';
import { ConversationContext } from './context';

export function buildPrompt(
  messages: Message[], 
  context: ConversationContext,
  newMessage: string
): string {
  const sections = [
    buildLessonContext(context),
    buildConversationHistory(messages),
    buildQuestionContext(context, newMessage),
    buildCurrentMessage(newMessage),
    buildInstructions()
  ];

  return sections.filter(Boolean).join('\n\n');
}

function buildLessonContext(context: ConversationContext): string {
  if (!context.currentLesson) return '';
  
  return `Current lesson context:
Title: ${context.currentLesson.title}
Content: ${context.currentLesson.content}`;
}

function buildConversationHistory(messages: Message[]): string {
  const recentMessages = messages.slice(-5);
  if (recentMessages.length === 0) return '';

  return `Recent conversation:
${recentMessages.map(msg => 
  `${msg.sender === 'user' ? 'Student' : 'Tutor'}: ${msg.content}`
).join('\n')}`;
}

function buildQuestionContext(context: ConversationContext, newMessage: string): string {
  if (!context.expectingAnswer || !context.lastQuestion) return '';

  return `Previous question asked: ${context.lastQuestion}
Student's response: ${newMessage}
Please provide feedback and continue the conversation naturally.`;
}

function buildCurrentMessage(message: string): string {
  return `Current message: ${message}`;
}

function buildInstructions(): string {
  return `Please respond as a helpful language tutor. Include:
1. Direct response to the student's message
2. Corrections if necessary
3. Examples or explanations when relevant
4. A follow-up question to maintain engagement`;
}