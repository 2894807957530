import { useState } from 'react';
import { motion } from 'framer-motion';
import { Volume2, Loader2, Play, Pause } from 'lucide-react';
import { synthesizeSpeech } from '../../lib/services/elevenlabs';
import { LearningLanguage } from '../../types/language';

interface VoiceButtonProps {
  text: string;
  language: LearningLanguage;
}

export default function VoiceButton({ text, language }: VoiceButtonProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  const handlePlay = async () => {
    if (isPlaying) {
      audio?.pause();
      setIsPlaying(false);
      return;
    }

    try {
      setIsLoading(true);
      
      if (!audio) {
        const audioData = await synthesizeSpeech(text, language);
        const blob = new Blob([audioData], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(blob);
        const newAudio = new Audio(url);
        
        newAudio.onended = () => {
          setIsPlaying(false);
        };
        
        setAudio(newAudio);
      }
      
      await audio?.play();
      setIsPlaying(true);
    } catch (error) {
      console.error('Voice playback error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={handlePlay}
      disabled={isLoading}
      className="inline-flex items-center gap-2 p-2 rounded-lg bg-emerald-100 text-emerald-700 hover:bg-emerald-200 transition-colors disabled:opacity-50"
    >
      {isLoading ? (
        <Loader2 className="h-4 w-4 animate-spin" />
      ) : isPlaying ? (
        <>
          <Pause className="h-4 w-4" />
          <Volume2 className="h-4 w-4" />
        </>
      ) : (
        <>
          <Play className="h-4 w-4" />
          <Volume2 className="h-4 w-4" />
        </>
      )}
    </motion.button>
  );
}