export const faq = {
  title: 'Frequently Asked Questions',
  description: 'Find answers to commonly asked questions',
  items: [
    {
      question: 'How do I start learning a new language?',
      answer: 'You can easily start by selecting your target language and setting your current proficiency level. Our AI tutor will create a personalized learning plan for you.'
    },
    {
      question: 'Can I track my learning progress?',
      answer: 'Yes, you can monitor your progress through your dashboard. You\'ll find detailed statistics about your completed lessons and proficiency level.'
    },
    {
      question: 'What are the premium membership benefits?',
      answer: 'Premium membership includes unlimited messages, advanced voice features, 24/7 priority support, and the ability to generate custom learning courses.'
    },
    {
      question: 'How can I get help?',
      answer: 'You can get help by contacting our support team through the complaint form in your profile page, or via WhatsApp.'
    }
  ]
};