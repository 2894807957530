// Extract message analysis logic into a separate file
export function analyzeMessage(message: string): {
  isQuestion: boolean;
  topic?: string;
  expectsResponse?: boolean;
} {
  return {
    isQuestion: detectQuestion(message),
    topic: extractTopic(message),
    expectsResponse: detectExpectation(message)
  };
}

function detectQuestion(message: string): boolean {
  const questionPatterns = [
    /\?$/,
    /^(what|how|why|when|where|who|could|can|would|will|do|does|did)/i,
    /tell me/i,
    /explain/i
  ];

  return questionPatterns.some(pattern => pattern.test(message));
}

function extractTopic(message: string): string | undefined {
  const topicPatterns = [
    /(?:about|regarding|concerning) (.+?)(\.|\?|$)/i,
    /^(.+?) (?:is|are|was|were)/i,
    /(?:learn|study|practice) (.+?)(\.|\?|$)/i
  ];

  for (const pattern of topicPatterns) {
    const match = message.match(pattern);
    if (match?.[1]) {
      return match[1].trim();
    }
  }
}

function detectExpectation(message: string): boolean {
  const expectationPatterns = [
    /(?:could|can|would|will) you/i,
    /please/i,
    /(?:tell|show|explain|help) me/i,
    /^(?:what|how|why|when|where|who)/i
  ];

  return expectationPatterns.some(pattern => pattern.test(message));
}