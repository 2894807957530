import { useState } from 'react';
import { Mail, X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useLanguage } from '../contexts/LanguageContext';

export default function EmailVerificationBanner() {
  const { currentUser, sendVerificationEmail } = useAuth();
  const { t } = useLanguage();
  const [isVisible, setIsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  if (!isVisible || !currentUser || currentUser.emailVerified) {
    return null;
  }

  const handleResendVerification = async () => {
    setLoading(true);
    setMessage('');
    try {
      await sendVerificationEmail();
      setMessage(t('auth.verificationSent'));
    } catch (error) {
      setMessage(t('auth.verificationError'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-yellow-50 border-b border-yellow-100">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-3">
            <Mail className="h-5 w-5 text-yellow-600" />
            <div>
              <p className="text-sm text-yellow-700">
                {t('auth.verifyEmail')}
              </p>
              {message && (
                <p className="text-sm text-yellow-600 mt-1">
                  {message}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-center gap-4">
            <button
              onClick={handleResendVerification}
              disabled={loading}
              className="text-sm text-yellow-700 hover:text-yellow-800 font-medium disabled:opacity-50"
            >
              {loading ? t('auth.sending') : t('auth.resendVerification')}
            </button>
            <button
              onClick={() => setIsVisible(false)}
              className="text-yellow-500 hover:text-yellow-600"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}