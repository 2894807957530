import { motion, AnimatePresence } from 'framer-motion';
import { X, Crown, CheckCircle } from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';
import PayPalSubscribeButton from './PayPalSubscribeButton';

interface SubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SubscriptionModal({ isOpen, onClose }: SubscriptionModalProps) {
  const { t, language } = useLanguage();

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
        dir={language === 'ar' ? 'rtl' : 'ltr'}
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-white rounded-xl shadow-xl max-w-md w-full overflow-hidden"
        >
          {/* Header */}
          <div className="p-6 bg-gradient-to-r from-yellow-500 to-amber-500 text-white relative">
            <button
              onClick={onClose}
              className="absolute top-4 left-4 p-1 hover:bg-white/20 rounded-full transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
            <div className="flex items-center gap-3">
              <Crown className="h-8 w-8" />
              <div>
                <h2 className="text-2xl font-bold">{t('subscription.premium')}</h2>
                <p className="text-yellow-100">{t('subscription.description')}</p>
              </div>
            </div>
          </div>

          {/* Benefits */}
          <div className="p-6">
            <div className="mb-6">
              <div className="text-2xl font-bold text-center mb-4">
                $19.95 {t('subscription.perMonth')}
              </div>
              <div className="space-y-4">
                {t('subscription.features', { returnObjects: true }).map((feature: string, index: number) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-center gap-3 bg-yellow-50 p-3 rounded-lg"
                  >
                    <CheckCircle className="h-5 w-5 text-yellow-600 flex-shrink-0" />
                    <span className="text-gray-700">{feature}</span>
                  </motion.div>
                ))}
              </div>
            </div>

            <PayPalSubscribeButton />
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}