export const ELEVENLABS_API_KEY = 'sk_0a9bc940fdf3dbbbbc1eefc714b0c488f64fd5b593d92855';
export const ELEVENLABS_API_URL = 'https://api.elevenlabs.io/v1';

// Brian's voice ID for all languages
const BRIAN_VOICE_ID = 'Yko7PKHZNXotIFUBG7I9';

export const VOICE_IDS = {
  en: BRIAN_VOICE_ID,
  fr: BRIAN_VOICE_ID,
  es: BRIAN_VOICE_ID,
  zh: BRIAN_VOICE_ID,
  ja: BRIAN_VOICE_ID
};

export const VOICE_SETTINGS = {
  stability: 0.90,           // Higher stability for clearer speech
  similarity_boost: 0.80,    // Higher similarity for more consistent voice
  style: 1.0,
  use_speaker_boost: true,
  model_id: 'eleven_multilingual_v2'
};