import { Message } from '../../types/chat';
import { LearningLanguage } from '../../types/language';
import { motion } from 'framer-motion';
import VoiceButton from '../voice/VoiceButton';

interface MessageBubbleProps {
  message: Message;
  isExpectingAnswer?: boolean;
  language?: LearningLanguage;
}

export default function MessageBubble({ 
  message,
  isExpectingAnswer,
  language
}: MessageBubbleProps) {
  const isAI = message.sender === 'ai';

  return (
    <div className={`flex ${isAI ? 'justify-start' : 'justify-end'} px-2 sm:px-0`}>
      <div
        className={`
          max-w-[85%] sm:max-w-[75%] rounded-2xl px-4 py-3
          ${isAI 
            ? 'bg-white border border-gray-200 text-gray-800' 
            : 'bg-emerald-600 text-white'}
          ${isExpectingAnswer ? 'border-emerald-500' : ''}
        `}
      >
        <p className="whitespace-pre-wrap text-[15px] leading-relaxed">{message.content}</p>
        
        {isAI && language && (
          <div className="mt-2">
            <VoiceButton text={message.content} language={language} />
          </div>
        )}
        
        {isExpectingAnswer && (
          <div className="mt-2 text-sm text-emerald-600 flex items-center gap-1">
            <span className="w-2 h-2 bg-emerald-500 rounded-full animate-pulse" />
            <span>Waiting for your answer...</span>
          </div>
        )}
      </div>
    </div>
  );
}