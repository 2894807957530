import { useLanguage } from '../contexts/LanguageContext';
import { Brain } from 'lucide-react';

export default function Footer() {
  const { language } = useLanguage();
  
  return (
    <footer className="bg-white border-t py-4 mt-auto">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="flex items-center gap-2">
            <span className="text-lg font-bold text-emerald-600">SpeakFlows</span>
          </div>
          <div className="flex items-center gap-2 text-gray-600 text-sm">
            <span>{language === 'ar' ? 'مدعوم بواسطة' : 'Powered by'}</span>
            <Brain className="h-4 w-4 text-emerald-600" />
            <span>{language === 'ar' ? 'الذكاء الاصطناعي' : 'AI'}</span>
          </div>
        </div>
      </div>
    </footer>
  );
}