import OpenAI from 'openai';
import { LearningLanguage } from '../../../types/language';

export const openai = new OpenAI({
  apiKey: 'sk-svcacct-efMddmCnugxpvp7F7owzr6K3Gg_mjRfJJl-IqMXEv-NacEawCmgidxJwcIvjQpDb_T3BlbkFJ6V3TNsGMTNIPlkaz8Nb-GfkolWBQz5cVPStiJPaSuueVINfaEDixtlgu2FN4uinAA',
  dangerouslyAllowBrowser: true
});

export const ASSISTANT_IDS: Record<LearningLanguage, string> = {
  en: 'asst_23GLsCD6VbmjUv0w0JN3FDO5',    // English AI Assistant
  fr: 'asst_ViuuifbWGiUC74v2OE0ikImL',    // French AI Assistant
  es: 'asst_siNTOgN3WdwIwgKJT0WIgXTf',    // Spanish AI Assistant
  zh: 'asst_YuJNSFH6p8yYUQ7K6VipA82B',    // Chinese AI Assistant
  ja: 'asst_h4ZAJY2RvBqq0CfyxhIFK7LY'     // Japanese AI Assistant
};

export const MAX_RETRIES = 3;
export const RETRY_DELAY = 1000;
export const RUN_TIMEOUT = 30000; // 30 seconds