import { useState } from 'react';
import { motion } from 'framer-motion';
import { Brain, Crown, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth } from '../../contexts/AuthContext';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { LearningLanguage } from '../../types/language';
import { generateCourse } from '../../lib/services/openai/course';
import { saveCourse } from '../../lib/services/firebase/courses';
import LanguageSelector from '../chat/LanguageSelector';
import LevelSelector from './LevelSelector';
import PayPalSubscribeButton from '../subscription/PayPalSubscribeButton';
import SavedCoursesButton from './SavedCoursesButton';
import SavedCoursesModal from './SavedCoursesModal';

export default function CourseGenerator() {
  const navigate = useNavigate();
  const { t } = useLanguage();
  const { currentUser } = useAuth();
  const { subscription } = useSubscription();
  const [step, setStep] = useState<'language' | 'level' | 'generating'>('language');
  const [selectedLanguage, setSelectedLanguage] = useState<LearningLanguage | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSavedCourses, setShowSavedCourses] = useState(false);

  const isPremiumUser = () => {
    return subscription?.planId === 'premium' && subscription?.status === 'active';
  };

  const handleLanguageSelect = (language: LearningLanguage) => {
    if (!isPremiumUser()) {
      return;
    }
    setSelectedLanguage(language);
    setStep('level');
  };

  const handleLevelSelect = async (selectedLevel: 'beginner' | 'intermediate' | 'advanced') => {
    if (!currentUser || !isPremiumUser() || !selectedLanguage) return;

    setIsGenerating(true);
    setError(null);

    try {
      const course = await generateCourse(
        selectedLanguage,
        selectedLevel,
        ['conversation', 'practice']
      );

      // Save course to Firebase
      const courseId = await saveCourse(currentUser.uid, course);

      // Navigate to chat with course
      navigate('/chat', { 
        state: { 
          course,
          courseId,
          selectedLesson: course.modules[0]?.lessons[0]
        }
      });
    } catch (error) {
      console.error('Course generation error:', error);
      setError(t('course.error'));
      setIsGenerating(false);
      setStep('level'); // Reset to level selection on error
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-8">
        <div className="text-center">
          <div className="inline-flex p-3 bg-emerald-100 rounded-full mb-4">
            <Brain className="h-8 w-8 text-emerald-600" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            {t('course.title')}
          </h2>
          <p className="text-gray-600">
            {t('course.description')}
          </p>
        </div>
        
        <SavedCoursesButton onClick={() => setShowSavedCourses(true)} />
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 text-red-600 rounded-lg text-center">
          {error}
        </div>
      )}

      {!isPremiumUser() && (
        <div className="mb-6 flex items-center justify-center gap-2 text-amber-600 bg-amber-50 p-4 rounded-lg">
          <Crown className="h-5 w-5" />
          <span>{t('subscription.courseGenerateRequired')}</span>
          <PayPalSubscribeButton />
        </div>
      )}

      {step === 'language' && (
        <LanguageSelector onSelect={handleLanguageSelect} />
      )}

      {step === 'level' && (
        <div className="space-y-6">
          {selectedLanguage && (
            <motion.button
              onClick={() => setStep('language')}
              className="flex items-center gap-2 text-emerald-600 hover:text-emerald-700"
            >
              <ArrowLeft className="h-4 w-4" />
              {t('course.back')}
            </motion.button>
          )}
          <LevelSelector 
            onSelect={handleLevelSelect} 
            isGenerating={isGenerating}
          />
        </div>
      )}

      <SavedCoursesModal 
        isOpen={showSavedCourses}
        onClose={() => setShowSavedCourses(false)}
      />
    </div>
  );
}