import { motion } from 'framer-motion';
import { Message, ConversationContext } from '../../types/chat';
import { LearningLanguage } from '../../types/language';
import MessageBubble from './MessageBubble';
import LoadingIndicator from './LoadingIndicator';

interface ChatMessagesProps {
  messages: Message[];
  isLoading: boolean;
  context: ConversationContext;
  selectedLanguage: LearningLanguage;
}

export default function ChatMessages({ 
  messages, 
  isLoading,
  context,
  selectedLanguage
}: ChatMessagesProps) {
  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-4">
      {messages.map((message, index) => (
        <motion.div
          key={message.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
        >
          <MessageBubble 
            message={message}
            isExpectingAnswer={
              message.sender === 'ai' && 
              context?.lastQuestion === message.content
            }
            language={selectedLanguage}
          />
        </motion.div>
      ))}
      
      {isLoading && <LoadingIndicator />}
    </div>
  );
}