export const subscription = {
  title: 'Subscription',
  premium: 'Premium Membership',
  free: 'Free Membership',
  freeDesc: 'Limited to 10 messages per day',
  perMonth: 'per month',
  description: 'Get unlimited access to all premium features',
  price: 'Only $19.95/month',
  features: [
    'Unlimited messages',
    'Advanced voice features',
    '24/7 Priority support',
    'Advanced AI assistance',
    'Advanced learning analytics',
    'Exclusive content'
  ],
  upgrade: 'Upgrade Membership',
  active: 'Active',
  messageLimit: 'You have reached your daily message limit',
  courseGenerateRequired: 'Course generation requires a premium subscription',
  startDate: 'Member since',
  endDate: 'Subscription ends',
  status: {
    active: 'Active',
    cancelled: 'Cancelled',
    expired: 'Expired'
  }
};