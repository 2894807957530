import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mail, Lock, User, LogIn } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { useLanguage } from '../contexts/LanguageContext';
import SocialLogin from '../components/auth/SocialLogin';

export default function Auth() {
  const { signIn, signUp, resetPassword } = useAuth();
  const { t, language, dir } = useLanguage();
  const navigate = useNavigate();
  
  const [mode, setMode] = useState<'login' | 'register' | 'reset'>('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (mode === 'login') {
        await signIn(email, password);
        navigate('/');
      } else if (mode === 'register') {
        await signUp(email, password, name);
      } else if (mode === 'reset') {
        await resetPassword(email);
      }
    } catch (err: any) {
      setError(t(`auth.${err.code}`) || t('auth.error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-teal-50 py-12 px-4" dir={dir()}>
      <div className="max-w-md mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-xl p-8"
        >
          <div className="text-center mb-8">
            <h1 className="text-2xl font-bold text-gray-900">
              {t(`auth.${mode}`)}
            </h1>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {mode === 'register' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('auth.name')}
                </label>
                <div className="relative">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full px-4 py-2 rounded-lg border focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                    required
                  />
                  <User className="absolute top-2.5 right-3 h-5 w-5 text-gray-400" />
                </div>
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('auth.email')}
              </label>
              <div className="relative">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                  required
                />
                <Mail className="absolute top-2.5 right-3 h-5 w-5 text-gray-400" />
              </div>
            </div>

            {mode !== 'reset' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('auth.password')}
                </label>
                <div className="relative">
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded-lg border focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                    required
                  />
                  <Lock className="absolute top-2.5 right-3 h-5 w-5 text-gray-400" />
                </div>
              </div>
            )}

            {error && (
              <div className="bg-red-50 text-red-600 p-3 rounded-lg text-sm">
                {error}
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-emerald-600 text-white py-2 px-4 rounded-lg hover:bg-emerald-700 transition flex items-center justify-center gap-2"
            >
              {loading ? (
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              ) : (
                <>
                  <LogIn className="h-5 w-5" />
                  {t(`auth.${mode}Button`)}
                </>
              )}
            </button>

            {mode === 'login' && <SocialLogin />}

            <div className="text-center space-y-2">
              {mode === 'login' ? (
                <>
                  <button
                    type="button"
                    onClick={() => setMode('register')}
                    className="text-emerald-600 hover:text-emerald-700"
                  >
                    {t('auth.needAccount')}
                  </button>
                  <button
                    type="button"
                    onClick={() => setMode('reset')}
                    className="block w-full text-emerald-600 hover:text-emerald-700"
                  >
                    {t('auth.forgotPassword')}
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  onClick={() => setMode('login')}
                  className="text-emerald-600 hover:text-emerald-700"
                >
                  {t('auth.haveAccount')}
                </button>
              )}
            </div>
          </form>
        </motion.div>
      </div>
    </div>
  );
}