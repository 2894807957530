import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { UserPreferencesProvider } from './contexts/UserPreferencesContext';
import { AssistantProvider } from './contexts/AssistantContext';
import AppRoutes from './routes';

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <LanguageProvider>
          <SubscriptionProvider>
            <NotificationProvider>
              <UserPreferencesProvider>
                <AssistantProvider>
                  <AppRoutes />
                </AssistantProvider>
              </UserPreferencesProvider>
            </NotificationProvider>
          </SubscriptionProvider>
        </LanguageProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}