import { useAuth } from '../contexts/AuthContext';
import { useLanguage } from '../contexts/LanguageContext';
import { GraduationCap, Globe } from 'lucide-react';
import { Link } from 'react-router-dom';
import SubscribeButton from './subscription/SubscribeButton';
import ProfileMenu from './profile/ProfileMenu';

export default function Header() {
  const { currentUser } = useAuth();
  const { t, language, setLanguage } = useLanguage();

  const toggleLanguage = () => {
    setLanguage(language === 'ar' ? 'en' : 'ar');
  };

  return (
    <header className="bg-emerald-700 text-white">
      <div className="container mx-auto px-4 py-4">
        <nav className="flex items-center justify-between" dir={language === 'ar' ? 'rtl' : 'ltr'}>
          {/* Logo */}
          <Link to="/" className="flex items-center gap-2">
            <GraduationCap className="h-8 w-8" />
            <div className="flex flex-col">
              <span className="text-xl font-bold">SpeakFlows</span>
              <span className="text-sm text-emerald-100 flex items-center gap-1">
                {language === 'ar' ? 'مدعوم بواسطة' : 'Powered by'} 
                <span className="text-yellow-300">AI</span>
              </span>
            </div>
          </Link>

          {/* Navigation */}
          <div className="flex items-center gap-4">
            {/* Language Selector */}
            <button 
              onClick={toggleLanguage}
              className="flex items-center gap-2 hover:text-emerald-200 transition px-3 py-2"
            >
              <Globe className="h-5 w-5" />
              <span className="hidden sm:inline">
                {language === 'ar' ? 'English' : 'العربية'}
              </span>
            </button>

            {currentUser ? (
              <div className="flex items-center gap-4">
                <SubscribeButton />
                <ProfileMenu />
              </div>
            ) : (
              <Link 
                to="/auth"
                className="bg-emerald-600 hover:bg-emerald-500 px-4 py-2 rounded-lg transition flex items-center gap-2"
              >
                <span>{t('app.signin')}</span>
              </Link>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}