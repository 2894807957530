import { Outlet } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import EmailVerificationBanner from '../components/EmailVerificationBanner';
import WhatsAppWidget from '../components/chat/WhatsAppWidget';

export default function Layout() {
  const { dir } = useLanguage();

  return (
    <div className="min-h-screen flex flex-col bg-gray-50" dir={dir()}>
      <Header />
      <EmailVerificationBanner />
      <main className="flex-1">
        <Outlet />
      </main>
      <Footer />
      <WhatsAppWidget />
    </div>
  );
}