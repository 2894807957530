import { app } from './ar/app';
import { auth } from './ar/auth';
import { ai } from './ar/ai';
import { course } from './ar/course';
import { benefits } from './ar/benefits';
import { languages } from './ar/languages';
import { subscription } from './ar/subscription';
import { faq } from './ar/faq';

export const ar = {
  app,
  auth,
  ai,
  course,
  benefits,
  languages,
  subscription,
  faq
};