import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useLanguage } from '../contexts/LanguageContext';
import { Course, Module } from '../types/course';
import LessonList from '../components/courses/LessonList';

export default function CoursePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useLanguage();
  const [course, setCourse] = useState<Course | null>(null);
  const [currentModule, setCurrentModule] = useState<Module | null>(null);

  useEffect(() => {
    const courseData = location.state?.course;
    if (!courseData) {
      navigate('/ai-assistant');
      return;
    }

    setCourse(courseData);
    setCurrentModule(courseData.modules[0]);
  }, [location.state, navigate]);

  if (!course || !currentModule) return null;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-lg p-6 border border-gray-100"
        >
          <h2 className="text-2xl font-bold text-gray-900 mb-6">
            {t('course.modules')}
          </h2>

          <div className="space-y-8">
            {course.modules.map((module) => (
              <LessonList
                key={module.id}
                course={course}
                currentModule={module}
              />
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
}