import { useState } from 'react';
import { motion } from 'framer-motion';
import { Crown } from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useSubscription } from '../../contexts/SubscriptionContext';
import SubscriptionModal from './SubscriptionModal';

export default function SubscribeButton() {
  const [showModal, setShowModal] = useState(false);
  const { t, dir } = useLanguage();
  const { subscription } = useSubscription();

  // Don't show button for premium users
  if (subscription?.planId === 'premium') {
    return null;
  }

  return (
    <>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setShowModal(true)}
        className="flex items-center gap-1.5 bg-gradient-to-r from-yellow-500 to-amber-500 text-white px-3 py-1.5 rounded-lg hover:from-yellow-600 hover:to-amber-600 transition shadow-sm text-sm"
        dir={dir()}
      >
        <Crown className="h-4 w-4" />
        <span>{t('subscription.upgrade')}</span>
      </motion.button>

      <SubscriptionModal 
        isOpen={showModal} 
        onClose={() => setShowModal(false)} 
      />
    </>
  );
}