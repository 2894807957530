export const auth = {
  // Page titles
  login: 'Login',
  register: 'Register',
  reset: 'Reset Password',
  
  // Form labels
  email: 'Email',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  name: 'Name',
  
  // Buttons
  loginButton: 'Login',
  registerButton: 'Register',
  resetButton: 'Reset Password',
  googleButton: 'Continue with Google',
  
  // Links and dividers
  or: 'or',
  needAccount: "Don't have an account?",
  haveAccount: 'Already have an account?',
  forgotPassword: 'Forgot Password?',
  
  // Messages
  signInRequired: 'Sign In Required',
  signInMessage: 'Please sign in to access all features',
  verifyEmail: 'Verify your email',
  emailVerified: 'Email verified',
  verificationSent: 'Verification email sent',
  verificationError: 'Failed to send verification email',
  
  // Errors
  invalidEmail: 'Invalid email address',
  weakPassword: 'Password is too weak',
  passwordMismatch: 'Passwords do not match',
  userNotFound: 'User not found',
  wrongPassword: 'Incorrect password',
  emailInUse: 'Email already in use',
  error: 'An error occurred. Please try again'
};