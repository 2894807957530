import { motion } from 'framer-motion';
import { Volume2, ChevronRight } from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';
import { Lesson } from '../../types/course';
import VoiceButton from '../voice/VoiceButton';

interface LessonContentProps {
  lesson: Lesson;
  onComplete: () => void;
}

export default function LessonContent({ lesson, onComplete }: LessonContentProps) {
  const { language } = useLanguage();

  return (
    <div className="space-y-6">
      {/* English Content */}
      <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold text-gray-900">{lesson.title}</h3>
          <VoiceButton text={lesson.content} language={lesson.language} />
        </div>
        <div className="prose max-w-none mb-6">
          {lesson.content}
        </div>
      </div>

      {/* Arabic Content */}
      <div className="bg-emerald-50 rounded-lg p-6 shadow-sm border border-emerald-100" dir="rtl">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold text-gray-900">{lesson.arabicTitle}</h3>
          <VoiceButton text={lesson.arabicContent} language="ar" />
        </div>
        <div className="prose max-w-none mb-6">
          {lesson.arabicContent}
        </div>
      </div>

      {/* Continue Button */}
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onComplete}
        className="w-full flex items-center justify-center gap-2 bg-emerald-600 text-white py-3 px-6 rounded-lg hover:bg-emerald-700 transition"
      >
        <span>{language === 'ar' ? 'الدرس التالي' : 'Next Lesson'}</span>
        <ChevronRight className="h-5 w-5" />
      </motion.button>
    </div>
  );
}