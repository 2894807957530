export const auth = {
  // Page titles
  login: 'تسجيل الدخول',
  register: 'إنشاء حساب',
  reset: 'إعادة تعيين كلمة المرور',
  
  // Form labels - Keep in English
  email: 'Email',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  name: 'Name',
  
  // Buttons - Keep in English
  loginButton: 'Login',
  registerButton: 'Register',
  resetButton: 'Reset Password',
  googleButton: 'Continue with Google',
  
  // Links and dividers
  or: 'أو',
  needAccount: 'ليس لديك حساب؟',
  haveAccount: 'لديك حساب بالفعل؟',
  forgotPassword: 'نسيت كلمة المرور؟',
  
  // Messages
  signInRequired: 'يجب تسجيل الدخول',
  signInMessage: 'يرجى تسجيل الدخول للوصول إلى جميع الميزات',
  verifyEmail: 'تحقق من بريدك الإلكتروني',
  emailVerified: 'تم التحقق من البريد الإلكتروني',
  verificationSent: 'تم إرسال رسالة التحقق',
  verificationError: 'فشل في إرسال رسالة التحقق',
  
  // Errors
  invalidEmail: 'البريد الإلكتروني غير صالح',
  weakPassword: 'كلمة المرور ضعيفة جداً',
  passwordMismatch: 'كلمات المرور غير متطابقة',
  userNotFound: 'لم يتم العثور على المستخدم',
  wrongPassword: 'كلمة المرور غير صحيحة',
  emailInUse: 'البريد الإلكتروني مستخدم بالفعل',
  error: 'حدث خطأ. يرجى المحاولة مرة أخرى'
};