export const course = {
  title: 'الدورات التعليمية',
  description: 'دورات مخصصة لمستواك',
  generate: 'إنشاء دورة جديدة',
  savedCourses: 'الدورات المحفوظة',
  noSavedCourses: 'لا توجد دورات محفوظة',
  continue: 'متابعة الدورة',
  back: 'رجوع',
  generating: 'جاري إنشاء الدورة...',
  generatingDescription: 'يرجى الانتظار بينما نقوم بإنشاء تجربة تعلم مخصصة لك',
  level: {
    beginner: 'مبتدئ',
    intermediate: 'متوسط',
    advanced: 'متقدم',
    beginnerDesc: 'مثالي للمبتدئين في تعلم اللغة',
    intermediateDesc: 'للمتعلمين ذوي المعرفة الأساسية الساعين للتحسين',
    advancedDesc: 'للمتعلمين ذوي الخبرة الساعين للطلاقة'
  }
};