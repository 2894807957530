import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';

interface Complaint {
  userId: string;
  subject: string;
  message: string;
  email: string;
  phone: string;
  timestamp: Date;
}

export async function addComplaint(complaint: Complaint): Promise<void> {
  try {
    await addDoc(collection(db, 'complaints'), {
      userId: complaint.userId,
      subject: complaint.subject,
      message: complaint.message,
      email: complaint.email,
      phone: complaint.phone,
      timestamp: serverTimestamp(),
      status: 'pending'
    });
  } catch (error) {
    console.error('Error adding complaint:', error);
    throw new Error('Failed to submit complaint');
  }
}