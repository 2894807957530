import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { UserSubscription } from '../../../types/subscription';

export async function initializeUserSubscription(userId: string): Promise<void> {
  const subscriptionRef = doc(db, 'subscriptions', userId);
  
  const initialSubscription: UserSubscription = {
    userId,
    planId: 'free',
    status: 'active',
    startDate: new Date(),
    endDate: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000), // 1 year
    messageCount: 0,
    lastResetDate: new Date()
  };

  await setDoc(subscriptionRef, {
    ...initialSubscription,
    startDate: initialSubscription.startDate.toISOString(),
    endDate: initialSubscription.endDate.toISOString(),
    lastResetDate: initialSubscription.lastResetDate.toISOString()
  });
}