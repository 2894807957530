import { Target, Brain, Sparkles } from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';

export default function BenefitsSection() {
  const { t } = useLanguage();

  const benefits = [
    {
      icon: <Brain className="h-6 w-6 text-emerald-600" />,
      title: t('benefits.ai'),
      description: t('benefits.ai_desc')
    },
    {
      icon: <Target className="h-6 w-6 text-emerald-600" />,
      title: t('benefits.practice'),
      description: t('benefits.practice_desc')
    },
    {
      icon: <Sparkles className="h-6 w-6 text-emerald-600" />,
      title: t('benefits.progress'),
      description: t('benefits.progress_desc')
    }
  ];

  return (
    <div className="w-full grid grid-cols-1 sm:grid-cols-3 gap-4">
      {benefits.map((benefit, index) => (
        <div key={index} className="bg-white/10 backdrop-blur-sm rounded-lg p-4 text-center">
          <div className="inline-flex p-2 bg-white/20 rounded-full mb-3">
            {benefit.icon}
          </div>
          <h3 className="font-medium text-white">{benefit.title}</h3>
          <p className="text-sm text-emerald-100 mt-1">{benefit.description}</p>
        </div>
      ))}
    </div>
  );
}