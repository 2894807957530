import { useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useLanguage } from '../../contexts/LanguageContext';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { usePayPal } from '../../lib/hooks/usePayPal';
import { handlePayPalSubscription } from '../../lib/services/paypal/subscription';
import { PAYPAL_SDK_CONFIG } from '../../lib/services/paypal/config';

export default function PayPalSubscribeButton() {
  const { currentUser } = useAuth();
  const { language } = useLanguage();
  const { refreshSubscription } = useSubscription();

  const handleApprove = useCallback(async (data: { subscriptionID: string }) => {
    if (!currentUser) return;
    
    try {
      await handlePayPalSubscription(currentUser.uid, data);
      await refreshSubscription();
    } catch (error) {
      console.error('Subscription error:', error);
      throw error;
    }
  }, [currentUser, refreshSubscription]);

  const handleError = useCallback((error: Error) => {
    console.error('PayPal error:', error);
  }, []);

  const { isLoading, error } = usePayPal({
    onApprove: handleApprove,
    onError: handleError
  });

  if (!currentUser) return null;

  return (
    <div className="flex flex-col items-center gap-4">
      {error && (
        <div className="text-sm text-red-600 bg-red-50 p-3 rounded-lg">
          {language === 'ar' ? 'حدث خطأ في عملية الدفع' : 'Payment processing error'}
        </div>
      )}
      
      <div 
        id={PAYPAL_SDK_CONFIG.buttonContainerId}
        className={isLoading ? 'opacity-50' : ''}
      />

      <img 
        src="https://www.paypalobjects.com/images/Debit_Credit_APM.svg" 
        alt={language === 'ar' ? 'بطاقات الدفع' : 'Payment methods'} 
        className="h-6"
      />
      
      <div className="text-sm text-gray-600 flex items-center gap-1">
        {language === 'ar' ? 'مدعوم من' : 'Powered by'}
        <img 
          src="https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-wordmark-color.svg" 
          alt="PayPal" 
          className="h-3.5"
        />
      </div>
    </div>
  );
}