import { OpenAIError } from '../errors';

export async function withRetry<T>(
  operation: () => Promise<T>,
  maxRetries: number = 3,
  delayMs: number = 1000
): Promise<T> {
  let lastError: Error | undefined;
  
  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error as Error;
      
      // Don't retry if it's a fatal error
      if (error instanceof OpenAIError && 
          (error.message.includes('cancelled') || 
           error.message.includes('expired'))) {
        throw error;
      }
      
      if (attempt < maxRetries - 1) {
        await new Promise(resolve => setTimeout(resolve, delayMs * Math.pow(2, attempt)));
        continue;
      }
    }
  }
  
  throw lastError || new OpenAIError('Operation failed after retries');
}