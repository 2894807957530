import { motion } from 'framer-motion';
import { Clock, CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { Course, Lesson, Module } from '../../types/course';

interface LessonListProps {
  course: Course;
  currentModule: Module;
}

export default function LessonList({ course, currentModule }: LessonListProps) {
  const navigate = useNavigate();
  const { language } = useLanguage();

  const handleLessonClick = (lesson: Lesson) => {
    navigate('/chat', {
      state: {
        course,
        currentModule,
        selectedLesson: lesson
      }
    });
  };

  return (
    <div className="space-y-4">
      <h3 className="font-medium text-gray-900 mb-4">
        {language === 'ar' ? currentModule.arabicTitle : currentModule.title}
      </h3>

      <div className="grid gap-3">
        {currentModule.lessons.map((lesson) => (
          <motion.button
            key={lesson.id}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleLessonClick(lesson)}
            className="w-full flex items-center justify-between p-4 rounded-lg border border-gray-200 hover:border-emerald-200 bg-white transition-all text-left"
          >
            <div className="flex items-center gap-3">
              <div className="flex-shrink-0">
                {lesson.completed ? (
                  <CheckCircle className="h-5 w-5 text-emerald-500" />
                ) : (
                  <div className="w-5 h-5 rounded-full border-2 border-gray-200" />
                )}
              </div>
              <div>
                <h4 className="font-medium text-gray-900">
                  {language === 'ar' ? lesson.arabicTitle : lesson.title}
                </h4>
              </div>
            </div>
            <div className="flex items-center gap-2 text-sm text-gray-500">
              <Clock className="h-4 w-4" />
              <span>{lesson.duration}m</span>
            </div>
          </motion.button>
        ))}
      </div>
    </div>
  );
}