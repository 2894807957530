import { useState } from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { useChat } from '../../hooks/useChat';
import { LearningLanguage } from '../../types/language';
import LanguageSelector from './LanguageSelector';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import PayPalSubscribeButton from '../subscription/PayPalSubscribeButton';

export default function DirectChat() {
  const { t } = useLanguage();
  const { subscription } = useSubscription();
  const { messages, isLoading, sendMessage, context } = useChat();
  const [selectedLanguage, setSelectedLanguage] = useState<LearningLanguage | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);

  const handleSendMessage = async (content: string) => {
    if (!content.trim() || !selectedLanguage || isLoading) return;

    if (subscription?.planId === 'free' && subscription.messageCount >= 10) {
      setError(t('subscription.messageLimit'));
      setShowUpgradePrompt(true);
      return;
    }

    try {
      await sendMessage(content, selectedLanguage);
      setError(null);
    } catch (err) {
      console.error('Chat error:', err);
      setError(t('chat.error'));
    }
  };

  if (!selectedLanguage) {
    return (
      <div className="h-[calc(100vh-4rem)] md:h-full">
        <LanguageSelector onSelect={setSelectedLanguage} />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-[calc(100vh-4rem)] md:h-full">
      <ChatMessages 
        messages={messages}
        isLoading={isLoading}
        context={context}
        selectedLanguage={selectedLanguage}
      />
      
      {error && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mx-4 mb-4 p-4 bg-red-50 text-red-600 rounded-lg"
        >
          <p className="text-sm mb-2">{error}</p>
          {showUpgradePrompt && (
            <div className="mt-4">
              <h4 className="font-medium mb-2">{t('subscription.upgrade')}</h4>
              <PayPalSubscribeButton />
            </div>
          )}
        </motion.div>
      )}

      <ChatInput
        onSendMessage={handleSendMessage}
        isLoading={isLoading}
        placeholder={
          context?.expectingAnswer 
            ? t('chat.typeAnswer')
            : t('chat.typeMessage')
        }
      />
    </div>
  );
}