import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { UserSubscription } from '../../../types/subscription';

export async function updateSubscriptionAfterPayment(
  userId: string, 
  subscriptionId: string
): Promise<void> {
  const subscriptionRef = doc(db, 'subscriptions', userId);
  
  // Create premium subscription
  const premiumSubscription: UserSubscription = {
    userId,
    planId: 'premium',
    status: 'active',
    startDate: new Date(),
    endDate: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000), // 1 year
    messageCount: 0,
    lastResetDate: new Date(),
    paypalSubscriptionId: subscriptionId
  };

  await setDoc(subscriptionRef, {
    ...premiumSubscription,
    startDate: premiumSubscription.startDate.toISOString(),
    endDate: premiumSubscription.endDate.toISOString(),
    lastResetDate: premiumSubscription.lastResetDate.toISOString()
  });
}

export async function verifySubscription(userId: string): Promise<boolean> {
  try {
    const subscriptionRef = doc(db, 'subscriptions', userId);
    const subscriptionDoc = await getDoc(subscriptionRef);
    
    if (!subscriptionDoc.exists()) return false;
    
    const data = subscriptionDoc.data();
    return data.planId === 'premium' && data.status === 'active';
  } catch (error) {
    console.error('Error verifying subscription:', error);
    return false;
  }
}