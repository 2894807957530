export const WHATSAPP_CONFIG = {
  agentId: '67cdfab0-9839-4fbd-bf24-e57d20785436',
  apiKey: 'b33c3ca6-87f8-4cc9-b514-728ffb04bcba',
  phoneNumber: '+966507855123',
  scriptUrl: 'https://widget.agentive.ai/widget.js',
  retryAttempts: 3,
  retryDelay: 2000, // Base delay in ms
  loadTimeout: 10000, // 10 seconds
  widgetConfig: {
    position: 'bottom-right',
    offset: {
      bottom: '80px',
      right: '20px'
    },
    zIndex: 999,
    hideWhenNotLoggedIn: true
  }
};