import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { MessageSquare } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useLanguage } from '../../contexts/LanguageContext';
import { WHATSAPP_CONFIG } from '../../lib/config/whatsapp';

export default function WhatsAppWidget() {
  const { currentUser } = useAuth();
  const { language } = useLanguage();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const timer = setTimeout(() => setIsVisible(true), 2000);
      return () => clearTimeout(timer);
    }
  }, [currentUser]);

  const handleWhatsAppClick = () => {
    const message = language === 'ar' 
      ? 'مرحباً! أحتاج إلى مساعدة في تعلم اللغة'
      : 'Hello! I need help with language learning';
      
    const url = `https://wa.me/${WHATSAPP_CONFIG.phoneNumber.replace('+', '')}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  if (!currentUser || !isVisible) return null;

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      className="fixed bottom-6 right-6 z-50"
    >
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={handleWhatsAppClick}
        className="bg-[#25D366] text-white p-4 rounded-full shadow-lg hover:shadow-xl transition-shadow"
      >
        <MessageSquare className="h-6 w-6" />
      </motion.button>
    </motion.div>
  );
}