export const faq = {
  title: 'الأسئلة الشائعة',
  description: 'إجابات على الأسئلة الأكثر شيوعاً',
  items: [
    {
      question: 'كيف يمكنني البدء في تعلم لغة جديدة؟',
      answer: 'يمكنك البدء بسهولة عن طريق اختيار اللغة التي تريد تعلمها، ثم تحديد مستواك الحالي. سيقوم المدرس الذكي بإنشاء خطة تعلم مخصصة لك.'
    },
    {
      question: 'هل يمكنني تتبع تقدمي في التعلم؟',
      answer: 'نعم، يمكنك متابعة تقدمك من خلال لوحة التحكم الخاصة بك. ستجد إحصائيات مفصلة عن دروسك المكتملة ومستوى إتقانك.'
    },
    {
      question: 'ما هي مميزات العضوية المميزة؟',
      answer: 'تتضمن العضوية المميزة رسائل غير محدودة، ميزات صوتية متقدمة، دعم متميز على مدار الساعة، وإمكانية إنشاء دورات تعليمية مخصصة.'
    },
    {
      question: 'كيف يمكنني الحصول على المساعدة؟',
      answer: 'يمكنك الحصول على المساعدة عن طريق التواصل مع فريق الدعم عبر نموذج الشكاوى في صفحة الملف الشخصي، أو عبر الواتساب.'
    }
  ]
};