import { OpenAIError } from './errors';
import { LearningLanguage } from '../../../types/language';
import { ASSISTANT_IDS } from './config';

export function validateLanguage(language: LearningLanguage): void {
  if (!ASSISTANT_IDS[language]) {
    throw new OpenAIError(`Unsupported language: ${language}`);
  }
}

export function validateResponse(response: string | undefined, errorMessage: string): string {
  if (!response) {
    throw new OpenAIError(errorMessage);
  }
  return response;
}

export function validateThread(threadId: string): void {
  if (!threadId) {
    throw new OpenAIError('Invalid thread ID');
  }
}

export function validateAssistant(assistantId: string): void {
  if (!assistantId) {
    throw new OpenAIError('Invalid assistant ID');
  }
}