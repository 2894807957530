import { motion } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';

interface LevelSelectorProps {
  onSelect: (level: 'beginner' | 'intermediate' | 'advanced') => void;
  isGenerating: boolean;
}

export default function LevelSelector({ onSelect, isGenerating }: LevelSelectorProps) {
  const { t } = useLanguage();

  const levels = [
    { 
      value: 'beginner',
      icon: '🌱',
      title: t('course.level.beginner'),
      description: t('course.level.beginnerDesc')
    },
    { 
      value: 'intermediate',
      icon: '🌿',
      title: t('course.level.intermediate'),
      description: t('course.level.intermediateDesc')
    },
    { 
      value: 'advanced',
      icon: '🌳',
      title: t('course.level.advanced'),
      description: t('course.level.advancedDesc')
    }
  ];

  return (
    <div className="space-y-6">
      {isGenerating ? (
        <div className="flex flex-col items-center justify-center p-12 space-y-4">
          <div className="w-16 h-16 border-4 border-emerald-500 border-t-transparent rounded-full animate-spin" />
          <p className="text-lg font-medium text-gray-900">
            {t('course.generating')}
          </p>
          <p className="text-sm text-gray-500">
            {t('course.generatingDescription')}
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          {levels.map((level) => (
            <motion.button
              key={level.value}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => onSelect(level.value as 'beginner' | 'intermediate' | 'advanced')}
              className="p-6 bg-white rounded-xl shadow-sm border border-gray-200 hover:border-emerald-200 transition-all"
              disabled={isGenerating}
            >
              <div className="text-center space-y-3">
                <span className="text-3xl block">{level.icon}</span>
                <div>
                  <h3 className="font-medium text-gray-900 mb-1">
                    {level.title}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {level.description}
                  </p>
                </div>
              </div>
            </motion.button>
          ))}
        </div>
      )}
    </div>
  );
}