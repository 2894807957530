import { LearningLanguage } from '../../../types/language';
import { Course } from '../../../types/course';
import { createThread, addMessageToThread } from './thread';
import { createRun, waitForRun } from './run';
import { OpenAIError } from './errors';
import { ASSISTANT_IDS } from './config';
import { withRetry } from './retry';

export async function generateCourse(
  language: LearningLanguage,
  level: string,
  goals: string[]
): Promise<Course> {
  return withRetry(async () => {
    try {
      const assistantId = ASSISTANT_IDS[language];
      if (!assistantId) {
        throw new OpenAIError(`No assistant configured for language: ${language}`);
      }

      const thread = await createThread();

      const prompt = `
        Create a detailed language learning course for ${language}.
        Level: ${level}
        Goals: ${goals.join(', ')}

        Requirements:
        1. Create 3-4 focused modules
        2. Each module should have 2-3 lessons
        3. Each lesson must include:
           - Clear learning objectives
           - Detailed content in both English and Arabic
           - Example conversations
           - Practice exercises
           - Cultural context
           - Pronunciation guides
        4. Make content engaging and practical
        5. Focus on real-world usage

        Format as JSON:
        {
          "title": "Course Title",
          "arabicTitle": "عنوان الدورة",
          "description": "Course Description",
          "arabicDescription": "وصف الدورة",
          "modules": [
            {
              "title": "Module Title",
              "arabicTitle": "عنوان الوحدة",
              "lessons": [
                {
                  "title": "Lesson Title",
                  "arabicTitle": "عنوان الدرس",
                  "content": "Detailed lesson content in English",
                  "arabicContent": "محتوى الدرس المفصل بالعربية",
                  "objectives": ["Objective 1", "Objective 2"],
                  "arabicObjectives": ["الهدف 1", "الهدف 2"],
                  "examples": ["Example 1", "Example 2"],
                  "arabicExamples": ["مثال 1", "مثال 2"],
                  "exercises": ["Exercise 1", "Exercise 2"],
                  "arabicExercises": ["تمرين 1", "تمرين 2"],
                  "culturalNotes": "Cultural context",
                  "arabicCulturalNotes": "السياق الثقافي",
                  "type": "text",
                  "duration": 15,
                  "language": "${language}"
                }
              ]
            }
          ]
        }`;

      await addMessageToThread(thread.id, prompt);
      const run = await createRun(thread.id, assistantId);
      const response = await waitForRun(thread.id, run.id);

      const jsonMatch = response.match(/\{[\s\S]*\}/);
      if (!jsonMatch) {
        throw new OpenAIError('Invalid course format returned');
      }

      const courseData = JSON.parse(jsonMatch[0]);
      return {
        id: `course-${Date.now()}`,
        ...courseData,
        language,
        level,
        duration: courseData.modules.reduce((total: number, module: any) => 
          total + module.lessons.reduce((moduleTotal: number, lesson: any) => 
            moduleTotal + (lesson.duration || 15), 0), 0)
      };
    } catch (error) {
      console.error('Course generation error:', error);
      throw new OpenAIError('Failed to generate course');
    }
  });
}