import { motion } from 'framer-motion';
import { Crown, Check, Calendar } from 'lucide-react';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { useLanguage } from '../../contexts/LanguageContext';
import { SUBSCRIPTION_PLANS } from '../../lib/config/subscription';
import PayPalSubscribeButton from '../subscription/PayPalSubscribeButton';

export default function SubscriptionDetails() {
  const { subscription } = useSubscription();
  const { t, language } = useLanguage();

  const isPremium = subscription?.planId === 'premium';
  const plan = SUBSCRIPTION_PLANS[isPremium ? 'premium' : 'free'];

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 border border-gray-100">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-emerald-100 rounded-lg">
            <Crown className="h-5 w-5 text-emerald-600" />
          </div>
          <h3 className="text-lg font-semibold">{t('subscription.title')}</h3>
        </div>
        {isPremium && (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gradient-to-r from-amber-500 to-yellow-500 text-white">
            <Crown className="h-4 w-4 mr-1" />
            Premium
          </span>
        )}
      </div>

      <div className="space-y-6">
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <div>
              <h4 className="font-medium text-gray-900">
                {isPremium ? t('subscription.premium') : t('subscription.free')}
              </h4>
              <p className="text-sm text-gray-500">
                {isPremium ? t('subscription.price') : t('subscription.freeDesc')}
              </p>
            </div>
          </div>

          {subscription?.startDate && (
            <div className="flex items-center gap-2 text-sm text-gray-600 border-t pt-4">
              <Calendar className="h-4 w-4" />
              <span>
                {t('subscription.startDate')}: {' '}
                {new Date(subscription.startDate).toLocaleDateString(
                  language === 'ar' ? 'ar-SA' : 'en-US',
                  { 
                    year: 'numeric', 
                    month: 'long', 
                    day: 'numeric' 
                  }
                )}
              </span>
            </div>
          )}
        </div>

        <div className="space-y-3">
          {t('subscription.features', { returnObjects: true }).map((feature: string, index: number) => (
            <div key={index} className="flex items-start gap-2">
              <div className={`p-1 rounded-full ${isPremium ? 'bg-emerald-100' : 'bg-gray-100'}`}>
                <Check className={`h-4 w-4 ${isPremium ? 'text-emerald-600' : 'text-gray-400'}`} />
              </div>
              <span className={`text-sm ${isPremium ? 'text-gray-700' : 'text-gray-500'}`}>
                {feature}
              </span>
            </div>
          ))}
        </div>

        {!isPremium && (
          <div className="pt-4">
            <PayPalSubscribeButton />
          </div>
        )}
      </div>
    </div>
  );
}