import { motion } from 'framer-motion';
import { Bot, LogIn, Brain, MessageSquare } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { useAuth } from '../contexts/AuthContext';
import BenefitsSection from '../components/dashboard/BenefitsSection';
import SubjectsGrid from '../components/subjects/SubjectsGrid';
import FAQ from '../components/FAQ';

export default function Dashboard() {
  const { t, language } = useLanguage();
  const { currentUser } = useAuth();

  return (
    <div>
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-6xl mx-auto space-y-12">
          {/* Hero Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gradient-to-br from-emerald-600 to-teal-600 rounded-xl p-8 text-white"
          >
            <div className="flex flex-col items-center text-center gap-6">
              <motion.div 
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring", stiffness: 260, damping: 20 }}
                className="p-4 bg-white/20 rounded-full"
              >
                <Brain className="h-12 w-12" />
              </motion.div>
              
              <div className="max-w-2xl">
                <h1 className="text-3xl md:text-4xl font-bold mb-4" dir={language === 'ar' ? 'rtl' : 'ltr'}>
                  {t('ai.title')}
                </h1>
                <p className="text-emerald-100 text-lg mb-6" dir={language === 'ar' ? 'rtl' : 'ltr'}>
                  {t('ai.description')}
                </p>
              </div>

              {/* Action Buttons */}
              {currentUser ? (
                <div className="flex flex-col sm:flex-row items-center gap-4 mb-8">
                  <Link 
                    to="/ai-assistant"
                    state={{ mode: 'chat' }}
                    className="w-full sm:w-auto flex items-center justify-center gap-2 bg-white text-emerald-600 px-8 py-3 rounded-lg font-medium hover:bg-emerald-50 transition transform hover:-translate-y-0.5 shadow-lg"
                  >
                    <MessageSquare className="h-5 w-5" />
                    {t('ai.startChat')}
                  </Link>
                  <Link 
                    to="/ai-assistant"
                    state={{ mode: 'course' }}
                    className="w-full sm:w-auto flex items-center justify-center gap-2 bg-emerald-500 text-white px-8 py-3 rounded-lg font-medium hover:bg-emerald-400 transition transform hover:-translate-y-0.5 shadow-lg"
                  >
                    <Brain className="h-5 w-5" />
                    {t('course.generate')}
                  </Link>
                </div>
              ) : (
                <div className="bg-white/10 backdrop-blur-sm rounded-lg p-8 text-center max-w-md mb-8">
                  <LogIn className="h-16 w-16 mx-auto mb-4 text-emerald-100" />
                  <h3 className="text-xl font-semibold mb-2">{t('auth.signInRequired')}</h3>
                  <p className="text-emerald-100 mb-6">{t('auth.signInMessage')}</p>
                  <Link 
                    to="/auth" 
                    className="inline-block bg-white text-emerald-600 px-6 py-2 rounded-lg font-medium hover:bg-emerald-50 transition"
                  >
                    {t('app.signin')}
                  </Link>
                </div>
              )}

              {/* Benefits Section */}
              <BenefitsSection />
            </div>
          </motion.div>

          {/* Language Selection */}
          <div className="space-y-8">
            <motion.h2 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-3xl font-bold text-gray-900 text-center mb-8"
              dir={language === 'ar' ? 'rtl' : 'ltr'}
            >
              {t('ai.selectLanguage')}
            </motion.h2>
            
            <div className="overflow-x-auto pb-4">
              <div className="min-w-max">
                <SubjectsGrid />
              </div>
            </div>
          </div>

          {/* FAQ Section */}
          <FAQ />
        </div>
      </div>
    </div>
  );
}