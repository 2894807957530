import { openai } from './config';
import { ThreadError } from './errors';
import { Message } from '../../../types/chat';

export async function createThread() {
  try {
    return await openai.beta.threads.create();
  } catch (error) {
    throw new ThreadError('Failed to create thread', error);
  }
}

export async function addMessageToThread(
  threadId: string,
  content: string,
  role: 'user' | 'assistant' = 'user'
) {
  try {
    return await openai.beta.threads.messages.create(threadId, {
      role,
      content
    });
  } catch (error) {
    throw new ThreadError('Failed to add message to thread', error);
  }
}

export async function addMessagesToThread(threadId: string, messages: Message[]) {
  try {
    for (const message of messages) {
      await addMessageToThread(
        threadId,
        message.content,
        message.sender === 'user' ? 'user' : 'assistant'
      );
    }
  } catch (error) {
    throw new ThreadError('Failed to add messages to thread', error);
  }
}