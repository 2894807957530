import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useAuth } from './AuthContext';
import { UserPreferences } from '../types/user';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface UserPreferencesContextType {
  preferences: UserPreferences | null;
  setPreferences: (prefs: UserPreferences) => Promise<void>;
  isLoading: boolean;
  error: string | null;
}

const UserPreferencesContext = createContext<UserPreferencesContextType | undefined>(undefined);

export function UserPreferencesProvider({ children }: { children: ReactNode }) {
  const { currentUser } = useAuth();
  const [preferences, setPreferencesState] = useState<UserPreferences | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let unsubscribed = false;

    async function loadPreferences() {
      if (!currentUser) {
        setPreferencesState(null);
        setIsLoading(false);
        return;
      }

      try {
        setError(null);
        const prefsDoc = await getDoc(doc(db, 'userPreferences', currentUser.uid));
        
        if (!unsubscribed) {
          if (prefsDoc.exists()) {
            setPreferencesState(prefsDoc.data() as UserPreferences);
          } else {
            setPreferencesState(null);
          }
        }
      } catch (err) {
        console.error('Error loading preferences:', err);
        if (!unsubscribed) {
          setError('Failed to load preferences');
        }
      } finally {
        if (!unsubscribed) {
          setIsLoading(false);
        }
      }
    }

    loadPreferences();

    return () => {
      unsubscribed = true;
    };
  }, [currentUser]);

  const setPreferences = async (prefs: UserPreferences) => {
    if (!currentUser) {
      throw new Error('User must be logged in to set preferences');
    }

    setIsLoading(true);
    setError(null);

    try {
      const prefsRef = doc(db, 'userPreferences', currentUser.uid);
      await setDoc(prefsRef, {
        ...prefs,
        updatedAt: new Date().toISOString()
      });
      setPreferencesState(prefs);
    } catch (err) {
      console.error('Error saving preferences:', err);
      setError('Failed to save preferences');
      throw new Error('Failed to save preferences');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <UserPreferencesContext.Provider value={{
      preferences,
      setPreferences,
      isLoading,
      error
    }}>
      {children}
    </UserPreferencesContext.Provider>
  );
}

export function useUserPreferences() {
  const context = useContext(UserPreferencesContext);
  if (context === undefined) {
    throw new Error('useUserPreferences must be used within a UserPreferencesProvider');
  }
  return context;
}