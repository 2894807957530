import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useLanguage } from '../contexts/LanguageContext';
import { Course, Lesson } from '../types/course';
import { Message } from '../types/chat';
import { generateChatResponse } from '../lib/openai';
import ChatMessages from '../components/chat/ChatMessages';
import ChatInput from '../components/chat/ChatInput';
import LessonContent from '../components/courses/LessonContent';

export default function ChatPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, language } = useLanguage();
  const [course, setCourse] = useState<Course | null>(null);
  const [currentLesson, setCurrentLesson] = useState<Lesson | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const courseData = location.state?.course;
    if (!courseData) {
      navigate('/ai-assistant');
      return;
    }

    setCourse(courseData);
    
    // Set initial lesson and generate welcome message
    const lesson = location.state?.selectedLesson || courseData.modules[0]?.lessons[0];
    if (lesson) {
      setCurrentLesson(lesson);
      
      // Generate initial teaching message
      const initialPrompt = `
        You are teaching this lesson:
        Title: ${lesson.title}
        Content: ${lesson.content}
        Objectives: ${lesson.objectives?.join(', ')}
        
        Start teaching this lesson in a conversational way. Include:
        1. Brief introduction
        2. Key points to learn
        3. Example usage
        4. Encourage student participation
      `;

      generateChatResponse(initialPrompt, courseData.language)
        .then(response => {
          const welcomeMessage: Message = {
            id: Date.now().toString(),
            content: response,
            sender: 'ai',
            timestamp: new Date()
          };
          setMessages([welcomeMessage]);
        })
        .catch(console.error);
    }
  }, [location.state, navigate, language]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isLoading || !currentLesson) return;

    const userMessage: Message = {
      id: Date.now().toString(),
      content: input.trim(),
      sender: 'user',
      timestamp: new Date()
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      // Include lesson context in the prompt
      const prompt = `
        Current lesson context:
        Title: ${currentLesson.title}
        Content: ${currentLesson.content}
        Objectives: ${currentLesson.objectives?.join(', ')}
        
        Student message: ${input}
        
        Respond as a helpful language tutor. Include:
        1. Clear explanation
        2. Examples if needed
        3. Corrections if necessary
        4. Encouragement
      `;

      const response = await generateChatResponse(prompt, course?.language || 'en');
      const aiMessage: Message = {
        id: (Date.now() + 1).toString(),
        content: response,
        sender: 'ai',
        timestamp: new Date()
      };
      setMessages(prev => [...prev, aiMessage]);
    } catch (err) {
      console.error('Chat error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!course || !currentLesson) {
    return null;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto space-y-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <LessonContent 
            lesson={currentLesson}
            onComplete={() => {
              // Move to next lesson
              const currentModuleIndex = course.modules.findIndex(m => 
                m.lessons.some(l => l.id === currentLesson.id)
              );
              const currentModule = course.modules[currentModuleIndex];
              const lessonIndex = currentModule.lessons.findIndex(l => l.id === currentLesson.id);
              const nextLesson = currentModule.lessons[lessonIndex + 1];
              
              if (nextLesson) {
                setCurrentLesson(nextLesson);
                // Generate introduction for next lesson
                const nextLessonPrompt = `
                  You are starting a new lesson:
                  Title: ${nextLesson.title}
                  Content: ${nextLesson.content}
                  Objectives: ${nextLesson.objectives?.join(', ')}
                  
                  Introduce this new lesson and start teaching it.
                `;
                
                generateChatResponse(nextLessonPrompt, course.language)
                  .then(response => {
                    const introMessage: Message = {
                      id: Date.now().toString(),
                      content: response,
                      sender: 'ai',
                      timestamp: new Date()
                    };
                    setMessages([introMessage]);
                  })
                  .catch(console.error);
              }
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden"
        >
          <div className="p-4 border-b bg-gradient-to-r from-emerald-50 to-teal-50">
            <h3 className="font-medium text-gray-900">
              {t('ai.askQuestion')}
            </h3>
          </div>

          <div className="h-[400px] flex flex-col">
            <ChatMessages 
              messages={messages}
              isLoading={isLoading}
              selectedLanguage={course.language}
            />
            
            <ChatInput
              input={input}
              setInput={setInput}
              onSubmit={handleSubmit}
              onGenerateQuiz={() => {}}
              isLoading={isLoading}
              isGeneratingQuiz={false}
              showQuizButton={false}
              placeholder={t('ai.askQuestion')}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
}