import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './Layout';
import Dashboard from '../pages/Dashboard';
import AIAssistant from '../pages/AIAssistant';
import Auth from '../pages/Auth';
import ChatPage from '../pages/ChatPage';
import CoursePage from '../pages/CoursePage';
import Profile from '../pages/Profile';
import PrivateRoute from './PrivateRoute';

export default function AppRoutes() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="auth" element={<Auth />} />
        
        {/* Protected Routes */}
        <Route path="ai-assistant" element={
          <PrivateRoute>
            <AIAssistant />
          </PrivateRoute>
        } />
        <Route path="chat" element={
          <PrivateRoute>
            <ChatPage />
          </PrivateRoute>
        } />
        <Route path="course" element={
          <PrivateRoute>
            <CoursePage />
          </PrivateRoute>
        } />
        <Route path="profile" element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        } />

        {/* Fallback route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}