export const subscription = {
  title: 'العضوية',
  premium: 'العضوية المميزة',
  free: 'العضوية المجانية',
  freeDesc: 'محدود إلى 10 رسائل يومياً',
  perMonth: 'شهرياً',
  description: 'احصل على تجربة تعلم متميزة مع جميع المميزات',
  price: 'فقط $19.95/شهرياً',
  features: [
    'رسائل غير محدودة',
    'ميزات صوتية متقدمة',
    'دعم متميز على مدار الساعة',
    'مساعد ذكي متقدم',
    'تحليلات متقدمة للتعلم',
    'محتوى حصري'
  ],
  upgrade: 'ترقية العضوية',
  active: 'نشط',
  messageLimit: 'لقد وصلت إلى الحد الأقصى للرسائل اليومية',
  courseGenerateRequired: 'يتطلب إنشاء الدورات اشتراكاً مميزاً',
  startDate: 'عضو منذ',
  endDate: 'تنتهي العضوية في',
  status: {
    active: 'نشط',
    cancelled: 'ملغي',
    expired: 'منتهي'
  }
};