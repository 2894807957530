import { Language } from './language';

export interface Assistant {
  id: string;
  name: string;
  arabicName: string;
  role: string;
  description: string;
  avatar: string;
  voiceId?: string;
  language: Language;
  specialties: string[];
  rating: number;
  reviews: number;
  flag: string;
}

export const assistants: Assistant[] = [
  {
    id: 'asst_23GLsCD6VbmjUv0w0JN3FDO5',
    name: 'Nada',
    arabicName: 'ندى',
    role: 'English Language Expert',
    description: 'Expert in English language instruction with a focus on natural conversation',
    avatar: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1976&auto=format&fit=crop',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
    language: 'en',
    specialties: ['pronunciation', 'grammar', 'conversation'],
    rating: 4.9,
    reviews: 1250,
    flag: '🇬🇧'
  },
  {
    id: 'asst_siNTOgN3WdwIwgKJT0WIgXTf',
    name: 'Nada',
    arabicName: 'ندى',
    role: 'Spanish Language Expert',
    description: 'Expert in Spanish language instruction with a focus on natural conversation',
    avatar: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1976&auto=format&fit=crop',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
    language: 'es',
    specialties: ['pronunciation', 'grammar', 'conversation'],
    rating: 4.8,
    reviews: 980,
    flag: '🇪🇸'
  }
];