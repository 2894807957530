import { ELEVENLABS_API_KEY, ELEVENLABS_API_URL, VOICE_IDS, VOICE_SETTINGS } from './config';
import { ElevenLabsError } from './errors';
import { LearningLanguage } from '../../../types/language';

// Cache for storing audio data
const audioCache: Record<string, ArrayBuffer> = {};

export async function synthesizeSpeech(text: string, language: LearningLanguage): Promise<ArrayBuffer> {
  try {
    const voiceId = VOICE_IDS[language];
    if (!voiceId) {
      throw new ElevenLabsError(`No voice configured for language: ${language}`);
    }

    // Check cache first
    const cacheKey = `${voiceId}-${text}`;
    if (audioCache[cacheKey]) {
      return audioCache[cacheKey];
    }

    // Adjust speaking rate based on language
    const speakingRate = language === 'zh' || language === 'ja' ? 0.85 : 1.0;

    const response = await fetch(`${ELEVENLABS_API_URL}/text-to-speech/${voiceId}`, {
      method: 'POST',
      headers: {
        'Accept': 'audio/mpeg',
        'Content-Type': 'application/json',
        'xi-api-key': ELEVENLABS_API_KEY,
      },
      body: JSON.stringify({
        text,
        model_id: VOICE_SETTINGS.model_id,
        voice_settings: {
          ...VOICE_SETTINGS,
          speaking_rate: speakingRate,
        }
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      console.error('Eleven Labs API error:', errorData);
      throw new ElevenLabsError(`Speech synthesis failed: ${response.statusText}`);
    }

    const audioData = await response.arrayBuffer();
    
    // Cache the result
    audioCache[cacheKey] = audioData;
    
    return audioData;
  } catch (error) {
    if (error instanceof ElevenLabsError) {
      throw error;
    }
    throw new ElevenLabsError('Failed to synthesize speech', error);
  }
}

export function clearAudioCache(): void {
  Object.keys(audioCache).forEach(key => {
    delete audioCache[key];
  });
}