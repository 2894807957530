import { openai } from '../config';
import { RunError } from '../errors';
import { RUN_TIMEOUT } from '../config';

export async function createRun(threadId: string, assistantId: string) {
  try {
    return await openai.beta.threads.runs.create(threadId, {
      assistant_id: assistantId
    });
  } catch (error) {
    throw new RunError('Failed to create run', error);
  }
}

export async function waitForRun(threadId: string, runId: string): Promise<string> {
  const startTime = Date.now();
  
  while (Date.now() - startTime < RUN_TIMEOUT) {
    try {
      const status = await openai.beta.threads.runs.retrieve(threadId, runId);
      
      if (status.status === 'completed') {
        const messages = await openai.beta.threads.messages.list(threadId);
        const content = messages.data[0]?.content[0];
        
        if (content?.type === 'text') {
          return content.text.value;
        }
        throw new RunError('No text content in response');
      }

      if (status.status === 'failed' || status.status === 'cancelled') {
        throw new RunError(`Run ${status.status}`);
      }

      await new Promise(resolve => setTimeout(resolve, 1000));
    } catch (error) {
      throw new RunError('Failed to check run status', error);
    }
  }

  throw new RunError('Run timed out');
}