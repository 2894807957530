export function validateSaudiPhone(phone: string): boolean {
  // Must be exactly 10 digits and start with 05
  const phoneRegex = /^05\d{8}$/;
  return phoneRegex.test(phone);
}

export function formatSaudiPhone(phone: string): string {
  const cleaned = phone.replace(/\D/g, '');
  if (cleaned.length === 10) {
    return `${cleaned.slice(0, 2)} ${cleaned.slice(2, 5)} ${cleaned.slice(5)}`;
  }
  return cleaned;
}

export function stripPhonePrefix(phone: string): string {
  return phone.replace(/^05/, '');
}