import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Phone } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useLanguage } from '../../contexts/LanguageContext';
import { updateUserPhone, getUserProfile } from '../../lib/services/firebase/user';
import { validateSaudiPhone } from '../../lib/utils/validation';

export default function ContactSettings() {
  const { currentUser } = useAuth();
  const { t } = useLanguage();
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const loadProfile = async () => {
      if (currentUser) {
        try {
          const profile = await getUserProfile(currentUser.uid);
          if (profile?.phoneNumber) {
            setPhone(profile.phoneNumber.slice(2)); // Remove '05' prefix
          }
        } catch (err) {
          console.error('Error loading profile:', err);
        }
      }
    };

    loadProfile();
  }, [currentUser]);

  const handlePhoneChange = (value: string) => {
    const cleaned = value.replace(/\D/g, '').slice(0, 8);
    setPhone(cleaned);
    setError('');
  };

  const handlePhoneUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser) return;

    const fullPhone = `05${phone}`;
    if (!validateSaudiPhone(fullPhone)) {
      setError(t('profile.contact.invalidPhone'));
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');

    try {
      await updateUserPhone(currentUser.uid, fullPhone);
      setMessage(t('profile.contact.updateSuccess'));
    } catch (err) {
      setError(t('profile.contact.updateError'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 border border-gray-100">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-emerald-100 rounded-lg">
          <Phone className="h-5 w-5 text-emerald-600" />
        </div>
        <h3 className="text-lg font-semibold">{t('profile.contact.title')}</h3>
      </div>

      <form onSubmit={handlePhoneUpdate} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {t('profile.contact.phone')}
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
              <span className="text-gray-500">05</span>
            </div>
            <input
              type="tel"
              value={phone}
              onChange={(e) => handlePhoneChange(e.target.value)}
              className="w-full pl-12 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-emerald-500"
              placeholder="XXXXXXXX"
              maxLength={8}
            />
          </div>
          <p className="mt-1 text-sm text-gray-500">
            {t('profile.contact.phoneFormat')}
          </p>
        </div>

        {error && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-red-600 text-sm bg-red-50 p-3 rounded-lg"
          >
            {error}
          </motion.div>
        )}

        {message && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-emerald-600 text-sm bg-emerald-50 p-3 rounded-lg"
          >
            {message}
          </motion.div>
        )}

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          type="submit"
          disabled={loading || !phone.trim()}
          className="w-full bg-emerald-600 text-white py-2 px-4 rounded-lg hover:bg-emerald-700 transition disabled:opacity-50 flex items-center justify-center gap-2"
        >
          {loading ? (
            <>
              <span className="inline-block h-4 w-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
              <span>{t('profile.saving')}</span>
            </>
          ) : (
            <span>{t('profile.save')}</span>
          )}
        </motion.button>
      </form>
    </div>
  );
}