import { Languages } from 'lucide-react';
import SubjectCard from './SubjectCard';
import { useLanguage } from '../../contexts/LanguageContext';

interface LanguageReview {
  name: string;
  arabicName: string;
  rating: number;
  reviews: number;
  flag: string;
}

export default function SubjectsGrid() {
  const { t, language } = useLanguage();

  const languageReviews: Record<string, LanguageReview> = {
    en: {
      name: 'Sarah',
      arabicName: 'سارة',
      rating: 4.9,
      reviews: 1250,
      flag: '🇬🇧'
    },
    ar: {
      name: 'Ahmad',
      arabicName: 'أحمد',
      rating: 4.8,
      reviews: 980,
      flag: '🇸🇦'
    },
    fr: {
      name: 'Layla',
      arabicName: 'ليلى',
      rating: 4.7,
      reviews: 850,
      flag: '🇫🇷'
    },
    es: {
      name: 'Omar',
      arabicName: 'عمر',
      rating: 4.8,
      reviews: 920,
      flag: '🇪🇸'
    },
    zh: {
      name: 'Nour',
      arabicName: 'نور',
      rating: 4.9,
      reviews: 1100,
      flag: '🇨🇳'
    },
    ja: {
      name: 'Zain',
      arabicName: 'زين',
      rating: 4.7,
      reviews: 890,
      flag: '🇯🇵'
    }
  };

  const subjects = [
    {
      code: 'en',
      title: t('languages.english'),
      description: t('subjects.english.desc'),
      image: 'https://images.unsplash.com/photo-1546410531-bb4caa6b424d?q=80&w=2071&auto=format&fit=crop',
    },
    {
      code: 'ar',
      title: t('languages.arabic'),
      description: t('subjects.arabic.desc'),
      image: 'https://images.unsplash.com/photo-1543783207-ec64e4d95325?q=80&w=2070&auto=format&fit=crop',
    },
    {
      code: 'fr',
      title: t('languages.french'),
      description: t('subjects.french.desc'),
      image: 'https://images.unsplash.com/photo-1502602898657-3e91760cbb34?q=80&w=2073&auto=format&fit=crop',
    },
    {
      code: 'es',
      title: t('languages.spanish'),
      description: t('subjects.spanish.desc'),
      image: 'https://images.unsplash.com/photo-1543783207-ec64e4d95325?q=80&w=2070&auto=format&fit=crop',
    },
    {
      code: 'zh',
      title: t('languages.chinese'),
      description: t('subjects.chinese.desc'),
      image: 'https://images.unsplash.com/photo-1547981609-4b6bfe67ca0b?q=80&w=2070&auto=format&fit=crop',
    },
    {
      code: 'ja',
      title: t('languages.japanese'),
      description: t('subjects.japanese.desc'),
      image: 'https://images.unsplash.com/photo-1545569341-9eb8b30979d9?q=80&w=2070&auto=format&fit=crop',
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {subjects.map((subject, index) => (
        <SubjectCard
          key={subject.code}
          {...subject}
          review={languageReviews[subject.code]}
          delay={index * 0.1}
        />
      ))}
    </div>
  );
}