import { useState, useEffect } from 'react';
import { loadPayPalScript, cleanupPayPalScript } from '../services/paypal/loader';
import { PAYPAL_SDK_CONFIG } from '../services/paypal/config';

interface UsePayPalOptions {
  onApprove?: (data: any) => Promise<void>;
  onError?: (error: Error) => void;
}

export function usePayPal({ onApprove, onError }: UsePayPalOptions = {}) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let mounted = true;

    const initializePayPal = async () => {
      try {
        await loadPayPalScript();
        
        if (!mounted || !window.paypal) return;

        // Clear any existing buttons
        const container = document.getElementById(PAYPAL_SDK_CONFIG.buttonContainerId);
        if (container) {
          container.innerHTML = '';
        }

        window.paypal.Buttons({
          style: {
            shape: 'pill',
            color: 'gold',
            layout: 'horizontal',
            label: 'subscribe'
          },
          createSubscription: (data: any, actions: any) => {
            return actions.subscription.create({
              plan_id: PAYPAL_SDK_CONFIG.planId
            });
          },
          onApprove: async (data: any) => {
            try {
              await onApprove?.(data);
            } catch (err) {
              console.error('Subscription approval error:', err);
              onError?.(err as Error);
            }
          }
        }).render(`#${PAYPAL_SDK_CONFIG.buttonContainerId}`);

        setIsLoading(false);
      } catch (err) {
        if (!mounted) return;
        const error = err instanceof Error ? err : new Error('Failed to initialize PayPal');
        setError(error);
        onError?.(error);
        setIsLoading(false);
      }
    };

    initializePayPal();

    return () => {
      mounted = false;
      cleanupPayPalScript();
    };
  }, [onApprove, onError]);

  return { isLoading, error };
}