import { openai } from './config';
import { OpenAIError } from './errors';
import { LearningLanguage } from '../../../types/language';
import { withRetry } from './utils/retry';
import { createThread, addMessageToThread } from './utils/thread';
import { createRun, waitForRun } from './utils/run';
import { ASSISTANT_IDS } from './config';

export async function generateChatResponse(
  message: string,
  language: LearningLanguage,
  context?: string
): Promise<string> {
  return withRetry(async () => {
    try {
      const assistantId = ASSISTANT_IDS[language];
      if (!assistantId) {
        throw new OpenAIError(`No assistant configured for language: ${language}`);
      }

      const thread = await createThread();

      // Add context if provided
      if (context) {
        await addMessageToThread(thread.id, context, 'system');
      }

      await addMessageToThread(thread.id, message);
      const run = await createRun(thread.id, assistantId);
      const response = await waitForRun(thread.id, run.id);

      return response;
    } catch (error) {
      console.error('Chat error:', error);
      throw new OpenAIError('Failed to generate chat response');
    }
  });
}